/* eslint no-eval: 0 */
export const AlomEncodeType2 = (Number) => {
  if (typeof Number === 'number' && Number % 1 === 0 && Number > 0) {
    var NumberArray = Number.toString().split('')
    var SumOfNumber = eval(NumberArray.join('+'))
    var SumOfLength = Number.toString().length
    while (SumOfNumber > 9) {
      let Digit = SumOfNumber.toString().split('')
      SumOfNumber = eval(Digit.join('+'))
    }
    while (SumOfLength > 9) {
      let Digit = SumOfLength.toString().split('')
      SumOfLength = eval(Digit.join('+'))
    }
    return Number + '' + SumOfLength + '' + SumOfNumber
  } else {
    return false
  }
}

export const getCookie = (cname) => {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      let value = c.substring(name.length, c.length)
      return value
    }
  }
  return ''
}
