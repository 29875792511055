import { useState, useEffect, useRef } from 'react';

export const useHover = () => {
	const [value, setValue] = useState(false);
	const ref = useRef(null);
	const handleMouseOver = () => setValue(true);
	const handleMouseOut = () => setValue(false);

	useEffect(
		() => {
			const node = ref.current;
			if (node) {
				node.addEventListener('mouseover', handleMouseOver);
				node.addEventListener('mouseout', handleMouseOut);
				return () => {
					node.removeEventListener('mouseover', handleMouseOver);
					node.removeEventListener('mouseout', handleMouseOut);
				};
			}
		},
		    // eslint-disable-next-line react-hooks/exhaustive-deps
		[ref.current] //recall only if ref changes
	);
		return [ref, value];
}