import React, { useRef, useEffect } from 'react'
import Chevron from '../Chevron/Chevron'
import { HamburgerToX } from 'docspera-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useMedia } from '../../hooks/useMedia'
import styled from 'styled-components'
import { labeledStyles } from '../../styles'

const PolicyPicker = (props) => {
  props = {
    ...{
      menuTitle: undefined,
      subtext: undefined,
      sections: [],
      menuHeaderBGColor: props.primaryColor
    },
    ...props
  }

  const tallScreen = useMedia('(min-height: 900px)')

  const findTitle = (id) => {
    let title
    props.sections.forEach((section) => {
      section.options.forEach((option) => {
        if (option.id === id) {
          title = option.title
        }
      })
    })
    return title
  }

  const emboldenFactors = (inputDescription) => {
    let factors = {
      Functionality: 'Functionality',
      Pain: 'Pain',
      Fall_Risk: 'Fall Risk',
      Number_of_Comorbidities: 'Number of Comorbidities',
      BMI: 'BMI',
      Age: 'Age',
      Days_from_Surgery: 'Days from Surgery'
    }

    let description = inputDescription
      .replace('Fall Risk', 'Fall_Risk')
      .replace('Number of Comorbidities', 'Number_of_Comorbidities')
      .replace('Days from Surgery', 'Days_from_Surgery')
    let array = description.split(' ')

    let newArray = array.map((word) => {
      // word = 'Weighted' for example
      let str = word
      // eslint-disable-next-line no-useless-escape
        .replace(/(~|`|!|@|#|$|%|^|&|\*|\(|\)|{|}|\[|\]|;|:|\"|'|<|,|\.|>|\?|\/|\\|\||-|\+|=)/g, '')
        .replace(' ', '_')
      if (factors[str]) {
        return `<b>${word}</b>`.split('_').join(' ')
      } else {
        return word
      }
    })
    return newArray.join(' ')
  }

  const onChangeListener = (id) => {
    return () => {
      props.setValue(id)
    }
  }

  // Close filter if user clicks outside of it
  const node = useRef(null)
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.current])

  const handleOutsideClick = (e) => {
    if (node.current.contains(e.target)) {
      return
    }
    props.setOpen(false)
  }

  return (
    // Entire Policy Picker
    <div style={{ width: '275px', marginRight: 20, userSelect: 'none', zIndex: 5 }} ref={node}>
      {/* Rank Policy Label: */}
      <p
        style={{
          fontSize: '14px',
          paddingBottom: '7px',
          fontFamily: props.fontFamily,
          color: labeledStyles.policyPicker.dropdownLabelColor
        }}
      >
        Rank Priority:
      </p>
      {/* Dropdown Box */}
      <div
        style={{
          backgroundColor: 'white',
          zIndex: 9,
          border: labeledStyles.policyPicker.menuHeaderBorder,
          borderRadius: labeledStyles.policyPicker.menuHeaderBorderRadius,
          boxShadow: props.isOpen && labeledStyles.policyPicker.dropdownShadow
        }}
      >
        <MenuHdr
          bgColor={props.menuHeaderBGColor}
          alignItems={props.isOpen ? 'flex-start' : 'center'}
          chevDisplay={props.value ? 'block' : 'none'}
          maxHeight={props.isOpen ? '' : '23px'}
          cursor={props.value ? 'pointer' : 'default'}
          onClick={props.value && (() => props.setOpen(!props.isOpen))}
          initial={false}
          animate={props.isOpen ? 'open' : 'collapsed'}
          fontFamily={props.fontFamily}
          color={props.menuHeaderFontColor}
          boxShadow={props.isOpen && labeledStyles.policyPicker.boxShadow}
          borderRadius={labeledStyles.policyPicker.menuHeaderBorderRadius}
        >
          <h3 style={{ fontFamily: labeledStyles.policyPicker.fontFamily }}>
            {findTitle(props.value)}
          </h3>
          {labeledStyles.policyPicker.hamburger ? (
            <HamburgerToX
              color={props.menuHeaderFontColor}
              isOpen={props.isOpen}
              setOpen={props.setOpen}
            />
          ) : (
            <motion.div
              style={{ height: 15, width: 15, marginTop: props.isOpen ? 5 : 0 }}
              alt='chevron'
              initial={false}
              animate={{
                transform: props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)'
              }}
            >
              <Chevron color={props.badgeFontColor} />
            </motion.div>
          )}
        </MenuHdr>

        {props.sections.map((section, idx) => {
          return (
            <AnimatePresence initial={false} key={`section-${idx}-animatepresence`}>
              {props.isOpen && (
                <motion.div
                  key={`section-${idx}`}
                  initial='collapsed'
                  animate={props.isOpen ? 'open' : 'collapsed'}
                  exit='collapsed'
                  variants={{
                    open: {
                      opacity: 1,
                      transition: {
                        duration: 0.1
                      }
                    },
                    collapsed: {
                      opacity: 0,
                      transition: {
                        duration: 0.1
                      }
                    }
                  }}
                >
                  <SectionHdr
                    key={`section-${idx}-hdr`}
                    bgColor={labeledStyles.policyPicker.sectionHdrBG}
                    color={labeledStyles.policyPicker.sectionHdrColor}
                    caps={labeledStyles.policyPicker.sectionHdrCaps}
                    fontWeight={labeledStyles.policyPicker.sectionHdrFontWeight}
                    borderBottom={labeledStyles.policyPicker.sectionHdrBorderBottom}
                    fontFamily={props.fontFamily}
                    fontSize={labeledStyles.policyPicker.labelFontSize}
                    boxShadow={labeledStyles.policyPicker.boxShadow}
                  >
                    {section.title}
                  </SectionHdr>
                  {section.options.map((option) => {
                    let checked = props.value === option.id
                    return (
                      <motion.div key={`section-${idx}-option-${option.id}-div`}>
                        <Option
                          key={`section-${idx}-option-${option.id}-input`}
                          border={
                            checked
                              ? `2px solid ${labeledStyles.policyPicker.optionSelectedBorder}`
                              : '2px solid white'
                          }
                          padding={tallScreen ? labeledStyles.policyPicker.optionPadding : ''}
                          initial='collapsed'
                          animate={props.isOpen ? 'open' : 'collapsed'}
                          boxShadow={labeledStyles.policyPicker.boxShadow}
                          borderRadius={labeledStyles.policyPicker.menuHeaderBorderRadius}
                        >
                          <Label
                            color={checked ? 'var(--blueDark)' : props.tertiaryColor}
                            paddingLeft={'10px'}
                            hoverColor={props.primaryColor}
                            padding={labeledStyles.policyPicker.labelPadding}
                            font={labeledStyles.policyPicker.labelFont}
                            weight={labeledStyles.policyPicker.labelWeight}
                          >
                            <Input
                              type='radio'
                              onChange={onChangeListener(option.id)}
                              checked={checked}
                            />
                            <SpanInput
                              type='radio'
                              size={labeledStyles.policyPicker.inputSize}
                              border={`${labeledStyles.policyPicker.inputBorderWidth} solid ${labeledStyles.policyPicker.inputBorderColor}`}
                              checked={checked}
                              // left={checked ? '15px' : '8px'}
                              left={'8px'}
                              top={tallScreen ? labeledStyles.policyPicker.inputTop : '7px'}
                            >
                              {/* Selected Background */}
                              <div
                                style={{
                                  width: '75%',
                                  height: '75%',
                                  borderRadius: 100,
                                  backgroundColor: checked ? props.primaryColor : 'white'
                                }}
                              ></div>
                            </SpanInput>
                            <div>
                              <OptionTitle
                                color={
                                  checked
                                    ? labeledStyles.policyPicker.optionTitleSelectedColor
                                    : props.tertiaryColor
                                }
                                fontFamily={labeledStyles.policyPicker.labelFont}
                                weight={labeledStyles.policyPicker.labelWeight}
                                fontSize={labeledStyles.policyPicker.optionTitleFontSize}
                              >
                                {option.title}
                              </OptionTitle>
                              {option.descriptions && (
                                <ul>
                                  {option.descriptions.map((description, idx3) => {
                                    return (
                                      <OptionDescription
                                        key={`section-${idx}-option-${option.id}-description-${idx3}`}
                                        color={checked ? 'black' : props.tertiaryColor}
                                        dangerouslySetInnerHTML={{
                                          __html: emboldenFactors(description)
                                        }}
                                        fontFamily={props.fontFamily}
                                      ></OptionDescription>
                                    )
                                  })}
                                </ul>
                              )}
                            </div>
                          </Label>
                        </Option>
                      </motion.div>
                    )
                  })}
                </motion.div>
              )}
            </AnimatePresence>
          )
        })}
      </div>
    </div>
  )
}

export default PolicyPicker

const MenuHdr = styled(motion.div)`
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  padding: 6px 6px 6px 10px;
  box-shadow: ${(props) => props.boxShadow && '-1px 1px 10px rgba(0, 0, 0, 0.2)'};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => props.alignItems};
  cursor: ${(props) => props.cursor};
  max-height: ${(props) => props.maxHeight};

  h1 {
    font-size: 16px;
    letter-spacing: 1px;
    font-family: ${(props) => props.fontFamily};
    margin: 0;
    cursor: ${(props) => props.cursor};
    font-weight: 500;
  }

  h3 {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.6px;
    cursor: ${(props) => props.cursor};
  }

  p {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 200;
    font-size: 14px;
    color: white;
    cursor: ${(props) => props.cursor};
  }
`

const SectionHdr = styled(motion.div)`
  font-family: ${(props) => props.fontFamily};
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  text-transform: ${(props) => props.caps && 'uppercase'};
  font-weight: ${(props) => props.fontWeight};
  border-radius: 3px;
  border-bottom: ${(props) => props.borderBottom};
  margin-top: 5px;
  padding: 3px 10px 3px 10px;
  letter-spacing: 0.7px;
  box-shadow: ${(props) => props.boxShadow && '-1px 1px 10px rgba(0, 0, 0, 0.2)'};
  font-size: 14px;
`

const Option = styled(motion.div)`
  background-color: white;
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  margin-top: 5px;
  box-shadow: ${(props) => props.boxShadow && '-1px 1px 10px rgba(0, 0, 0, 0.2)'};
  position: relative;
  cursor: pointer;
  padding: ${(props) => props.padding};

  span {
    font-size: 14px;
  }
  h3 {
    margin: 0;
    color: ${(props) => props.color};
  }
  ul {
    margin-top: 5px;
    padding-left: 20px;
  }
`

const Label = styled(motion.label)`
  font-family: ${(props) => props.font};
  font-weight: ${(props) => props.weight} !important;
  display: flex;
  border-radius: 3px;
  margin-left: 20px;
  padding: ${(props) => props.padding};
  padding-left: ${(props) => props.paddingLeft};
  cursor: pointer;

  span {
    color: ${(props) => props.color};
    font-weight: 500;
    font-size: 14px;
  }

  &:hover {
    span,
    li {
      color: black;
    }

    h3 {
      color: ${(props) => props.hoverColor};
    }
  }
`

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
`

const SpanInput = styled.span`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => props.border};
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  border-radius: 50%;
  background-color: ${(props) => props.bgColor};
  cursor: pointer;
`

const OptionTitle = styled.h3`
  font-family: ${(props) => props.fontFamily};
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  cursor: pointer;
`

const OptionDescription = styled.li`
  font-family: ${(props) => props.fontFamily};
  font-size: 12px;
  font-weight: 200;
  color: ${(props) => props.color};
  cursor: pointer;
`
