import React, { useContext, useEffect } from 'react'
import { labeledStyles } from '../../styles'
import { Context as TabContext, setActiveTab } from '../../context/TabContext'

const Tabs = ({ tabs }) => {
  const { state: tabState, dispatch } = useContext(TabContext)

  useEffect(() => {
    dispatch(setActiveTab(tabs[0].name))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      style={{
        marginLeft: 30,
        width: '100%',
        display: 'flex',
        marginTop: -15
      }}
    >
      {tabs.map((tab, idx) => {
        return (
          // Each Tab
          <div
            key={idx}
            onClick={() => {
              dispatch(setActiveTab(tab.name))
            }}
            style={{ boxSizing: 'border-box' }}
          >
            <span
              style={{
                fontWeight: tabState.activeTab === tab.name ? 500 : 100,
                marginRight: 50,
                color: 'black',
                fontFamily: labeledStyles.filters.fontFamily,
                backgroundColor: 'transparent',
                cursor: 'pointer'
              }}
            >
              {tab.name}
            </span>

            {tabState.activeTab === tab.name && (
              <div style={{ display: 'flex', marginTop: 10 }}>
                <div
                  style={{
                    height: 5,
                    width: '100%',
                    boxSizing: 'border-box',
                    backgroundColor: labeledStyles.policyPicker.primary,
                    borderRadius: 100,
                    cursor: 'pointer'
                  }}
                ></div>
                <div style={{ height: 5, width: 70, backgroundColor: 'transparent' }}></div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default Tabs
