import React from 'react'
import { ContextProvider } from './context/Context'
import { ContextProvider as TabContextProvider } from './context/TabContext'
import Dashboard from './views/Dashboard'
import { labeledStyles } from './styles'
import './App.scss'
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom'
import SurveyResponses from './components/SurveyResponses/SurveyResponses'

function App () {
  if (window.location.href.includes('velys')) {
    let faviconEl = document.getElementById('favicon')
    faviconEl.setAttribute('href', '/faviconVelys.ico')
  }
  return (
    <ContextProvider>
      <Router>
        <Routes>
          <Route path="/:surveyId" element={<ViewSurveyRoute />}/>
          <Route path="/" element={<DashboardRoute/>}/>
        </Routes>
      </Router>
    </ContextProvider>
  )
}

function DashboardRoute () {
  return <TabContextProvider>
    <div id="app" style={{ backgroundColor: labeledStyles.dashboard.backgroundColor }}>
      <Dashboard/>
    </div>
  </TabContextProvider>
}

function ViewSurveyRoute () {
  let params = useParams();
  return <div id="app" style={{ backgroundColor: labeledStyles.dashboard.backgroundColor }}>
    <SurveyResponses patientId={params.surveyId}/>
  </div>
}

export default App
