import React, { useContext } from 'react'
import { Context, setNotification } from '../../context/Context'
import './Notification.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const Notification = () => {
  setTimeout(() => {
    dispatch(setNotification(null))
  }, 5000)

  const { state, dispatch } = useContext(Context)
  return (
    <div id='notification'>
      <div
        className='flex align-center space-between'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <p className='ellipsis'>{state.notification}</p>
        <button
          type='button'
          className='btn-close small'
          onClick={() => dispatch(setNotification(null))}
        >
          <div>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </button>
      </div>
    </div>
  )
}

export default Notification
