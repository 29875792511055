import React from 'react'
import styled, { keyframes } from 'styled-components'

const LoadingSplash = (props) => {
  props = {
    ...{
      color: 'var(--blueDocspera)',
      backgroundColor: 'white',
      size: '16px'
    },
    ...props
  }

  return (
    <Div>
      <div id='loadingsplash'>
        <Throbber color={props.color} backgroundColor={props.backgroundColor} size={props.size} />
      </div>
    </Div>
  )
}

const throbberSpin = keyframes`
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
`

const Throbber = styled.div`
  border: 3px dotted ${(props) => props.color};
  border-radius: 100%;
  height: 8px;
  width: 8px;
  position: relative;
  -webkit-animation: ${throbberSpin} 1.5s cubic-bezier(0.67, 0.06, 0.18, 0.93) infinite;
  animation: ${throbberSpin} 1.5s cubic-bezier(0.67, 0.06, 0.18, 0.93) infinite;

  &:before {
    background: ${(props) => props.backgroundColor};
    content: '';
    display: block;
    position: absolute;
    height: 5px;
    width: 5px;
    top: -4px;
    left: 3px;
    transform: rotate(90deg);
  }
`

const Div = styled.div`
  & #loadingsplash {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    & > div.throbber {
      border-color: var(--blueDocspera);
    }

    & h1 {
      color: var(--blueDocspera);
      font-weight: 600;
    }
  }
`

export default LoadingSplash
