import React, { useReducer } from 'react'

const reducer = (state, action) => {
  let result = {
    ...state
  }
  switch (action.type) {
    // Set Active Tab
    case 'set-active-tab':
      result.activeTab = action.payload
      break
    default:
      return result
  }
  return result
}

const initialResponseState = {
  tab: null
}

const setActiveTab = (tabString) => ({
  type: 'set-active-tab',
  payload: tabString
})

const initState = () => initialResponseState
const Context = React.createContext({})

function ContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, undefined, initState)

  return <Context.Provider value={{ state, dispatch }}>{props.children}</Context.Provider>
}

export { Context, ContextProvider, setActiveTab }
