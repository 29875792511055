import React from 'react'
import { sendBulkSurveysApi } from '../../api/api'
import { setNotification } from '../../context/Context'
import { Button } from 'docspera-components'
import { labeledStyles } from '../../styles'

const RenderButtons = ({
  status,
  handleViewIntakeClick,
  handleRebookClick,
  eventId,
  surveyId,
  dispatch
}) => {
  const resendIntake = () => {
    sendBulkSurveysApi([eventId]).then((response) => {
      if (response?.status === 200) {
        dispatch(setNotification('Resending of Intake was Successful.'))
      } else {
        dispatch(setNotification('Request failed. Please contact Customer Support.'))
      }
    })
  }

  const handleEditForm = () => {
    // https://surveys.d4.docvisor.com/61f84200f6ff0b68af77f363
    const url = `/survey/${surveyId}`
    window.open(url, '_blank')
  }

  switch (status) {
    case 0:
    case 1:
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginTop: 5 }}>
            <Button
              id='resend-btn'
              content='Rebook'
              size='medium'
              background={labeledStyles.statusActionButtons.background}
              hoverStyles={{
                background: labeledStyles.statusActionButtons.hoverBackground,
                borderColor: labeledStyles.statusActionButtons.hoverBorderColor,
                color: labeledStyles.statusActionButtons.hoverColor
              }}
              borderColor={labeledStyles.statusActionButtons.borderColor}
              color={labeledStyles.statusActionButtons.color}
              fontFamily={labeledStyles.statusActionButtons.fontFamily}
              onClick={(e) => handleRebookClick(e)}
            />
          </div>
          <div style={{ margin: 5, fontWeight: 900 }}>
            <Button
              id='resend-btn'
              content='Resend Form'
              size='medium'
              background={labeledStyles.statusActionButtons.background}
              hoverStyles={{
                background: labeledStyles.statusActionButtons.hoverBackground,
                borderColor: labeledStyles.statusActionButtons.hoverBorderColor,
                color: labeledStyles.statusActionButtons.hoverColor
              }}
              borderColor={labeledStyles.statusActionButtons.borderColor}
              color={labeledStyles.statusActionButtons.color}
              fontFamily={labeledStyles.statusActionButtons.fontFamily}
              onClick={() => resendIntake()}
            />
          </div>
          <div style={{ margin: 5 }}>
            <Button
              id='resend-btn'
              content='View Form'
              size='medium'
              background={labeledStyles.statusActionButtons.background}
              hoverStyles={{
                background: labeledStyles.statusActionButtons.hoverBackground,
                borderColor: labeledStyles.statusActionButtons.hoverBorderColor,
                color: labeledStyles.statusActionButtons.hoverColor
              }}
              borderColor={labeledStyles.statusActionButtons.borderColor}
              color={labeledStyles.statusActionButtons.color}
              fontFamily={labeledStyles.statusActionButtons.fontFamily}
              onClick={(e) => handleViewIntakeClick(e)}
            />
          </div>
          <Button
            id='resend-btn'
            content='Edit Form'
            size='medium'
            background={labeledStyles.statusActionButtons.background}
            hoverStyles={{
              background: labeledStyles.statusActionButtons.hoverBackground,
              borderColor: labeledStyles.statusActionButtons.hoverBorderColor,
              color: labeledStyles.statusActionButtons.hoverColor
            }}
            borderColor={labeledStyles.statusActionButtons.borderColor}
            color={labeledStyles.statusActionButtons.color}
            fontFamily={labeledStyles.statusActionButtons.fontFamily}
            onClick={() => handleEditForm()}
          />
        </div>
      )
    case 2:
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ margin: 5, zIndex: 100 }}>
            <Button
              id='resend-btn'
              content='Rebook'
              size='medium'
              background={labeledStyles.statusActionButtons.background}
              hoverStyles={{
                background: labeledStyles.statusActionButtons.hoverBackground,
                borderColor: labeledStyles.statusActionButtons.hoverBorderColor,
                color: labeledStyles.statusActionButtons.hoverColor
              }}
              borderColor={labeledStyles.statusActionButtons.borderColor}
              color={labeledStyles.statusActionButtons.color}
              fontFamily={labeledStyles.statusActionButtons.fontFamily}
              onClick={(e) => handleRebookClick(e)}
            />
          </div>
          <Button
            id='resend-btn'
            content='View Form'
            size='medium'
            background={labeledStyles.statusActionButtons.background}
            hoverStyles={{
              background: labeledStyles.statusActionButtons.hoverBackground,
              borderColor: labeledStyles.statusActionButtons.hoverBorderColor,
              color: labeledStyles.statusActionButtons.hoverColor
            }}
            borderColor={labeledStyles.statusActionButtons.borderColor}
            color={labeledStyles.statusActionButtons.color}
            fontFamily={labeledStyles.statusActionButtons.fontFamily}
            onClick={(e) => handleViewIntakeClick(e)}
          />
        </div>
      )
    case 3:
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ margin: 5 }}>
            <div style={{ margin: 5, zIndex: 100 }}>
              <Button
                id='resend-btn'
                content='Rebook Again'
                size='medium'
                background={labeledStyles.statusActionButtons.background}
                hoverStyles={{
                  background: labeledStyles.statusActionButtons.hoverBackground,
                  borderColor: labeledStyles.statusActionButtons.hoverBorderColor,
                  color: labeledStyles.statusActionButtons.hoverColor
                }}
                borderColor={labeledStyles.statusActionButtons.borderColor}
                color={labeledStyles.statusActionButtons.color}
                fontFamily={labeledStyles.statusActionButtons.fontFamily}
                onClick={(e) => handleRebookClick(e)}
              />
            </div>
            <Button
              id='resend-btn'
              content='View Form'
              size='medium'
              background={labeledStyles.statusActionButtons.background}
              hoverStyles={{
                background: labeledStyles.statusActionButtons.hoverBackground,
                borderColor: labeledStyles.statusActionButtons.hoverBorderColor,
                color: labeledStyles.statusActionButtons.hoverColor
              }}
              borderColor={labeledStyles.statusActionButtons.borderColor}
              color={labeledStyles.statusActionButtons.color}
              fontFamily={labeledStyles.statusActionButtons.fontFamily}
              onClick={(e) => handleViewIntakeClick(e)}
            />
          </div>
        </div>
      )
    default:
      return
  }
}

export default RenderButtons
