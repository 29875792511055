import React from 'react'

const CovidIcon = () => {
	return (
		<svg x="0px" y="0px" viewBox="0 0 30.8 32.7" >
			<g transform="translate(-731.496 -475.467)">
				<path  style={{fill:'none', stroke:'#1478B4', strokeWidth:1.7, strokeLinecap:'round', strokeLinejoin:'round'}} d="M732.9,499.5h9.1l1.9,0.6l7.6,0.6v2.8l-8.3,0.6"/>
				<path style={{fill:'none', stroke:'#1478B4', strokeWidth:1.7, strokeLinecap:'round', strokeLinejoin:'round'}} d="M732.9,505.1h3.2l5.2,2.3h12.4l7.2-5.4l-1.4-1.9l-7,2.8l-1.1,0"/>
				<path style={{fill:'none', stroke:'#1478B4', strokeWidth:1.7, strokeLinecap:'round', strokeLinejoin:'round'}} d="M757,495.7h-20.1v-10l10.1-5.4l10.1,5.4L757,495.7z"/>
				<path style={{fill:'none', stroke:'#1478B4', strokeWidth:1.7, strokeLinecap:'round'}} d="M732.6,484.1l14.3-7.6l14.3,7.6"/>
				<line style={{fill:'none', stroke:'#1478B4', strokeWidth:1.7, strokeLinecap:'round'}} x1="746.9" y1="484.9" x2="746.9" y2="489"/>
				<circle style={{fill:'#1478B4'}} cx="746.9" cy="492" r="1"/>
			</g>
		</svg>
	)
}

export default CovidIcon