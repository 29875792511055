import React, { useState, useEffect, useContext, useRef } from 'react'
import { Context, getHeatCalendarAvailability } from '../../context/Context'
import './HeatCalendar.scss'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Chevron from "../Chevron/Chevron";

const HeatCalendar = ({ value, style, handler }) => {
  const { state, dispatch } = useContext(Context)
  const [showCalendar, setShowCalendar] = useState(false)
  const [currentMonthView, setCurrentMonthView] = useState(moment(value).format('YYYYMM'))
  const [isHovering, setIsHovering] = useState(false)
  const [physicianInfo, setPhysicianInfo] = useState(null)

  const calRef = useRef(null)
  const dateInputRef = useRef(null)
  useEffect(() => {
    setCurrentMonthView(moment(value).format('YYYYMM'))
  }, [value])

  useEffect(() => {
   document.addEventListener("mousedown", handleClickOutside);
   return () => {
    // Unbind the event listener on clean up
    document.removeEventListener("mousedown", handleClickOutside);
  };
  }, [])

  useEffect(() => {
    // Get Availability for Heat Calendar
    let start = moment(currentMonthView).clone().startOf('month')
    let end = moment(currentMonthView).clone().endOf('month')
    getHeatCalendarAvailability(start, end, state.rebookInfo.physician, dispatch)
    setMonthState(buildMonth(currentMonthView))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonthView])

  useEffect(() => {
    const formattedResponse = state.heatCalendarAvailability?.reduce((acc, curr) => {
      acc[curr.date] = { ...curr }
      return acc
    }, {})
    setPhysicianInfo(formattedResponse)
  }, [state.heatCalendarAvailability])

  function handleClickOutside(event) {
    //  If date input is click do nothing and let it's clickhandler handle it
    if(dateInputRef.current && dateInputRef.current.contains(event.target)) return

    if (calRef.current && !calRef.current.contains(event.target)) {
      setShowCalendar(false)
    }
  }

  const buildMonth = (currentMonthView) => {
    const startOfMonth = moment(currentMonthView, 'YYYYMMDD').clone().startOf('month')
    let date = startOfMonth.clone().subtract(startOfMonth.day(), 'day')

    // build weeks array for rendering
    const weeks = [
      [] // first week
    ]

    for (let i = 0; i < 42; i++) {
      let weekNum = Math.floor(i / 7)
      if (weeks[weekNum] === undefined) {
        weeks[weekNum] = []
      }
      let objDate = date.toDate()
      const dayObj = {
        date: date.date(),
        weekNum: weekNum,
        moment: objDate,
        currentMonthView: date.isSame(moment(currentMonthView, 'YYYYMM'), 'month')
      }
      weeks[weekNum].push(dayObj)
      date = date.add(1, 'd')
    }
    return weeks
  }

  const [monthState, setMonthState] = useState(buildMonth(currentMonthView))

  const previousMonth = () => {
    const newMonth = moment(currentMonthView, 'YYYYMM').subtract(1, 'month').format('YYYYMM')
    setCurrentMonthView(newMonth)
    setMonthState(buildMonth(newMonth))
  }

  const nextMonth = () => {
    const newMonth = moment(currentMonthView, 'YYYYMM').add(1, 'month').format('YYYYMM')
    setCurrentMonthView(newMonth)
    setMonthState(buildMonth(newMonth))
  }

  const findBackgroundColor = (percent, hoursAvailable, totalCases) => {
    if (hoursAvailable === 0 && totalCases === 0) {
      return 'var(--light-gray)'
    } else {
      // docsperaBlue = rgba(65, 170, 252)
      return `rgba(65, 170, 252, ${percent})`
    }
  }

  return (
    <div className='calendar-picker-container no-select'>
      <label>
        <input
          type='text'
          value={moment(value, 'YYYYMMDD').format('MMM DD, YYYY')}
          onBlur={() => !isHovering && setShowCalendar(false)}
          onClick={() => setShowCalendar(!showCalendar)}
          style={{
            width: `${style.width ? style.width : '100%'}`,
            cursor: 'pointer',
            backgroundColor: 'white'
          }}
          readOnly
          ref={dateInputRef}
        />
        <Chevron />
      </label>

      {showCalendar && (
        <div
          className='calendar-picker-popover'
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          ref={calRef}
        >
          <header className='flex justify-center align-center direction-row'>
            <div
              className='flex justify-center align-center'
              onClick={() => {
                previousMonth()
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} size='lg' />
            </div>
            <div>
              <h4>{moment(currentMonthView).format('MMMM YYYY')}</h4>
            </div>
            <div
              className='flex justify-center align-center'
              onClick={() => {
                nextMonth()
              }}
            >
              <FontAwesomeIcon icon={faAngleRight} size='lg' />
            </div>
          </header>

          <div id='weekdays' className='flex direction-row nowrap justify-center'>
            <div>Sun</div>
            <div>Mon</div>
            <div>Tue</div>
            <div>Wed</div>
            <div>Thu</div>
            <div>Fri</div>
            <div>Sat</div>
          </div>

          <div id='month'>
            {monthState?.map((week, index) => {
              return (
                <div className='week flex direction-row nowrap justify-center' key={index}>
                  {week.map((day, index) => {
                    let isSelected = moment(value).isSame(day.moment, 'd')
                    let physicianDateInfo = physicianInfo[moment(day.moment).format('YYYYMMDD')]
                    let totalCases = physicianDateInfo?.totalBooked
                    let hoursAvailable = physicianDateInfo?.hoursAvailable
                    let percentBookedColor = findBackgroundColor(
                      physicianDateInfo?.percentBooked,
                      hoursAvailable,
                      totalCases
                    )
                    return (
                      <div className={`day flex justify-center align-center`} key={index}>
                        {totalCases > 0 && <span className='total-cases'>{totalCases}</span>}
                        <div
                          className={`flex justify-center align-center
                            ${isSelected && ' selected'}
                            ${!day.currentMonthView ? ' not-month' : ''}
                            `}
                          style={{
                            backgroundColor: percentBookedColor,
                            color: hoursAvailable === 0 && totalCases === 0 && 'grey'
                          }}
                          data-date={moment(day.moment).format('YYYYMMDD')}
                          onClick={(event) => {
                            event.stopPropagation()
                            handler(event)
                            setShowCalendar(false)
                          }}
                        >
                          {day.date}
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>

          {/* Legend */}
          <div style={{ fontSize: 10, width: '90%', margin: '20px auto' }}>
            <span
              style={{
                marginBottom: 20,
                display: 'inline-block',
                textAlign: 'right',
                width: '100%'
              }}
            >
              Top Right Number = Total Cases
            </span>
            {/* Gradient Bar */}
            <div
              style={{
                width: '100%',
                height: 10,
                background: 'linear-gradient(90deg, rgba(0,150,250,0) 0%, rgba(0,150,250,1) 100%)',
                borderRadius: 5,
                border: '1px solid var(--light-gray)',
                marginBottom: 5
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <span>0% Booked </span>
              <span>100%</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default HeatCalendar
