import React, { useContext, useEffect, useState, useCallback } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import update from 'immutability-helper'
import { LoadingSplash } from 'docspera-components'
import { useInfiniteScroll } from 'react-infinite-scroll-hook'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import {
  Context,
  setSortByAction,
  setPatientPriority,
  setSortDirectionAction,
  getNextPageCancelledCases,
  getNextPagePatientPriorities,
} from '../../context/Context'

import Row from '../Row/Row'
import { useMedia } from '../../hooks/useMedia'
import HoverDescription from '../HoverDescription'
import { Context as TabContext } from '../../context/TabContext'

import { labeledStyles } from '../../styles'
import './Table.scss'

const Table = (props) => {
  const { state, dispatch } = useContext(Context)
  const { state: tabState } = useContext(TabContext)
  const [patients, setPatients] = useState(state.patients)
  let smallComputer = useMedia('(max-width: 1500px)')
  let smallerComputer = useMedia('(max-width: 1360px)')
  let draggablesDisappear = useMedia('(min-width: 1000px)')
  const [prioritySelectorOffset, setPrioritySelectorOffset] = useState(0.009)

  useEffect(() => {
    if (tabState.activeTab === 'Rebooking') {
      if (state.patients) {
        const rebookablePatients = state.patients.filter((patient) => patient.status !== 3)
        setPatients(rebookablePatients)
      }
    } else if (tabState.activeTab === 'Cancelled (Form Not Sent)') {
      if (state.cancelledCases) {
        setPatients(state.cancelledCases)
      }
    } else if (tabState.activeTab === 'Already Rebooked') {
      if (state.patients) {
        const rebookedPatients = state.patients.filter((patient) => patient.status === 3)
        setPatients(rebookedPatients)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState.activeTab, JSON.stringify(state.patients), state.cancelledCases])

  const onDragEnd = (result) => {
    // onDragEnd

    const { destination, source, reason } = result;

    if (!destination || reason === 'CANCEL') {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    setPatients((prevPatients) =>
      update(prevPatients, {
        $splice: [
          [source.index, 1],
          [destination.index, 0, prevPatients[source.index]]
        ]
      })
    )
    const staticOffset = 0.00051
    const smallerStaticOffset = 0.00001
    let newPriority,
      destinationPriority = patients[destination.index].priority.priority,
      preDestinationPriority = patients[destination.index - 1]?.priority.priority,
      postDestinationPriority = patients[destination.index + 1]?.priority.priority

    if (destination.index !== null || destination.index !== undefined) {
      if (destination.index !== 0 && destination.index !== patients.length - 1) {
        newPriority = (preDestinationPriority + postDestinationPriority) / 2
        if(destination.index >  source.index) {
          if(newPriority >= destinationPriority) {
            const difference = newPriority - destinationPriority
            if((newPriority - (difference) - smallerStaticOffset) > postDestinationPriority) {
              newPriority -= difference + smallerStaticOffset
            } else {
              newPriority -= difference + (smallerStaticOffset / 2)
            }
          }
        } else {
          if(newPriority < destinationPriority) {
            const difference = destinationPriority - newPriority
            if((newPriority + (difference) + smallerStaticOffset) < preDestinationPriority) {
              newPriority += difference + smallerStaticOffset
            } else {
              newPriority += difference + (smallerStaticOffset / 2)
            }
          }
        }
      } else if (destination.index === 0) {
        newPriority = destinationPriority + staticOffset
      } else {
        newPriority = destinationPriority - staticOffset
      }
      setPatientPriority(state, dispatch, patients[source.index].id, newPriority)

      // console for troubleshooting drag and drop priority
      // console.log({
      //   predestinationIdx: destination.index - 1,
      //   destinationIdx: destination.index,
      //   nextdestinationIdx: destination.index + 1,
      //   sourceIdx: source.index,
      //   nextdestinationPriority: postDestinationPriority,
      //   ...(destination.index !== 0 && {predestinationPriority: preDestinationPriority}),
      //   destinationPriority: destinationPriority,
      //   computedPriority: newPriority,
      //   comptSign:  destination.index <  source.index ? "up": "down"
      // })
    }
  }


  const renderRow = useCallback((patient, index, snapshot) => {
    // console.log(patient.id)
    return (
      <Row
        index={index}
        key={index}
        patient={patient}
        snapshot={snapshot}
        patientsLength={patients.length}
        handleSurveyClick={props.handleSurveyClick}
        prioritySelectorOffset={prioritySelectorOffset}
        setPrioritySelectorOffset={setPrioritySelectorOffset}
      />
    )
    //
  }, [patients, prioritySelectorOffset, props.handleSurveyClick])

  const handleSort = (e) => {
    dispatch(setSortByAction(e.target.getAttribute('name')))
    dispatch(setSortDirectionAction(state.sorting.sort === 'asc' ? 'desc' : 'asc'))
  }

  const hdrIsSelected = (name, boxColor) => {
    let hdrButtonStyles = {
      padding: '3px 5px',
      userSelect: 'none'
    }

    if (name === state.sorting.sortBy) {
      hdrButtonStyles = {
        display: 'flex',
        backgroundColor: boxColor,
        borderRadius: '3px',
        padding: '3px 5px',
        alignItems: 'center'
      }
    } else {
      hdrButtonStyles = {
        display: 'flex',
        borderRadius: '3px',
        padding: '3px 5px',
        alignItems: 'center'
      }
    }
    return hdrButtonStyles
  }

  const handleHasNextPage = () => {
    if (tabState.activeTab === 'Cancelled (Form Not Sent)') {
      return state.cancelledCasesNextPageUri !== null
    } else {
      return state.patientsNextPageUri !== null
    }
  }

  const handleLoadMore = () => {
    if (tabState.activeTab === 'Cancelled (Form Not Sent)') {
      if (state.cancelledCasesNextPageUri !== null) {
        getNextPageCancelledCases(state, dispatch)
      }
    } else {
      if (state.patientsNextPageUri !== null) {
        getNextPagePatientPriorities(state, dispatch)
      }
    }
  }

  let infiniteRef = useInfiniteScroll({
    loading: state.isLoading,
    hasNextPage: handleHasNextPage,
    onLoadMore: handleLoadMore,
    scrollContainer: 'window'
  })

  const findCaretStyles = (factor) => {
    if (`${factor}` === state.sorting.sortBy) {
      return {
        color: labeledStyles.tableHeader.caretActiveColor,
        cursor: 'pointer'
      }
    } else {
      return {
        color: labeledStyles.tableHeader.caretInactiveColor,
        cursor: 'pointer'
      }
    }
  }

  if (patients !== null)
    return (
      <TableDiv
        id='table'
        role='table'
        aria-label='Patient Priority'
        backgroundColor={labeledStyles.table.background}
        paddingLeft={labeledStyles.table.paddingLeft}
      >
        <TableHeaderDiv
          backgroundColor={labeledStyles.tableHeader.background}
          cursor={tabState.activeTab !== 'Cancelled (Form Not Sent)' ? 'pointer' : 'default'}
        >
          {'priority.priority' !== state.sorting.sortBy &&
            draggablesDisappear &&
            tabState.activeTab === 'Rebooking' && (
              <div>
                <img
                  className='draggable-button'
                  src='../../images/draggable-button.svg'
                  alt='Make rows draggable'
                  name='priority.priority'
                  onClick={handleSort}
                />
              </div>
            )}

          {tabState.activeTab === 'Rebooking' && (
            // {/* P R I O R I T Y */}
            <div id='priority-hdr-div' onClick={handleSort} name='priority.priority'>
              <div style={hdrIsSelected('priority.priority', labeledStyles.tableHeader.boxColor)}>
                <TableHeader
                  name='priority.priority'
                  className='priority-hdr'
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  color={labeledStyles.tableHeader.color}
                  textTransform={labeledStyles.tableHeader.textTransform}
                >
                  Priority
                </TableHeader>
                <motion.i
                  onClick={handleSort}
                  name='priority.priority'
                  className='fa fa-caret-down'
                  initial={false}
                  animate={{
                    transform:
                      state.sorting.sort === 'asc' && 'priority.priority' === state.sorting.sortBy
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                  }}
                  style={findCaretStyles('priority.priority')}
                />
              </div>
            </div>
          )}

          {/* P H Y S I C I A N */}
          <div
            className='physician-col'
            style={tabState.activeTab !== 'Rebooking' ? { marginLeft: 30 } : {}}
          >
            <div style={hdrIsSelected('provider.name', labeledStyles.tableHeader.boxColor)}>
              <TableHeader
                className='physician-h5'
                fontFamily={labeledStyles.tableHeader.fontFamily}
                fontWeight={labeledStyles.tableHeader.fontWeight}
                fontSize={labeledStyles.tableHeader.fontSize}
                color={labeledStyles.tableHeader.color}
                textTransform={labeledStyles.tableHeader.textTransform}
                marginLeft={'10px'}
              >
                Physician
              </TableHeader>
            </div>
          </div>

          {/* P A T I E N T */}
          <div
            onClick={tabState.activeTab !== 'Cancelled (Form Not Sent)' ? handleSort : null}
            name='name'
            className='patient-col'
          >
            <div style={hdrIsSelected('name', labeledStyles.tableHeader.boxColor)}>
              <TableHeader
                onClick={tabState.activeTab !== 'Cancelled (Form Not Sent)' ? handleSort : null}
                name='name'
                fontFamily={labeledStyles.tableHeader.fontFamily}
                fontSize={labeledStyles.tableHeader.fontSize}
                fontWeight={labeledStyles.tableHeader.fontWeight}
                color={labeledStyles.tableHeader.color}
                textTransform={labeledStyles.tableHeader.textTransform}
                marginLeft={'25px'}
              >
                Patient
              </TableHeader>
              {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
                <motion.i
                  onClick={handleSort}
                  name='name'
                  className='fa fa-caret-down'
                  initial={false}
                  animate={{
                    transform:
                      state.sorting.sort === 'asc' && state.sorting.sortBy === 'name'
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                  }}
                  style={findCaretStyles('name')}
                />
              )}
            </div>
          </div>

          {/*  A G E  */}
          <div
            className='age-col'
            name='dob'
            onClick={tabState.activeTab !== 'Cancelled (Form Not Sent)' ? handleSort : null}
          >
            <div style={hdrIsSelected('dob', labeledStyles.tableHeader.boxColor)}>
              <TableHeader
                name='dob'
                onClick={tabState.activeTab !== 'Cancelled (Form Not Sent)' ? handleSort : null}
                fontFamily={labeledStyles.tableHeader.fontFamily}
                fontSize={labeledStyles.tableHeader.fontSize}
                fontWeight={labeledStyles.tableHeader.fontWeight}
                color={labeledStyles.tableHeader.color}
                textTransform={labeledStyles.tableHeader.textTransform}
                marginLeft={'25px'}
              >
                {tabState.activeTab === 'Cancelled (Form Not Sent)' ? 'DOB / AGE' : 'Age'}
              </TableHeader>
              {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
                <motion.i
                  onClick={handleSort}
                  name='dob'
                  className='fa fa-caret-down'
                  initial={false}
                  animate={{
                    transform:
                      state.sorting.sort === 'asc' && 'dob' === state.sorting.sortBy
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                  }}
                  style={findCaretStyles('dob')}
                />
              )}
            </div>
          </div>

          {/*  M R N  */}
          {tabState.activeTab === 'Cancelled (Form Not Sent)' && (
            <div className='mrn-col' name='mrn'>
              <div style={hdrIsSelected('mrn', labeledStyles.tableHeader.boxColor)}>
                <TableHeader
                  name='mrn'
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  color={labeledStyles.tableHeader.color}
                  textTransform={labeledStyles.tableHeader.textTransform}
                  marginLeft={'15px'}
                >
                  MRN
                </TableHeader>
              </div>
            </div>
          )}

          {/*  F A L L   R I S K   */}
          {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
            <div className='fall-risk-col' name='factors.fallRisk' onClick={handleSort}>
              <div style={hdrIsSelected('factors.fallRisk', labeledStyles.tableHeader.boxColor)}>
                <TableHeader
                  name='factors.fallRisk'
                  onClick={handleSort}
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  color={labeledStyles.tableHeader.color}
                  textTransform={labeledStyles.tableHeader.textTransform}
                  marginLeft={'15px'}
                >
                  {smallerComputer ? 'Fall R.' : 'Fall Risk'}
                </TableHeader>
                <HoverDescription description='Fall Risk is based on 2 questions about recent falls and joint instability. The scores represent the percentile that a specific patient is in relative to other patients’ responses.' />
                <motion.i
                  onClick={handleSort}
                  name='factors.fallRisk'
                  className='fa fa-caret-down'
                  initial={false}
                  animate={{
                    transform:
                      state.sorting.sort === 'asc' && 'factors.fallRisk' === state.sorting.sortBy
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                  }}
                  style={findCaretStyles('factors.fallRisk')}
                />
              </div>
            </div>
          )}

          {/*  P A I N  */}
          {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
            <div className='pain-col' name='factors.pain' onClick={handleSort}>
              <div style={hdrIsSelected('factors.pain', labeledStyles.tableHeader.boxColor)}>
                <TableHeader
                  name='factors.pain'
                  onClick={handleSort}
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  color={labeledStyles.tableHeader.color}
                  textTransform={labeledStyles.tableHeader.textTransform}
                  marginLeft={'25px'}
                >
                  Pain
                </TableHeader>
                <HoverDescription description='Pain is composed of 3 questions. The first is the Visual Analogue Scale (VAS) to allow patients to numerically rate their pain. The others relate to narcotic usage and if those dosages (if any) have increased recently. The scores represent the percentile that a specific patient is in relative to other patients’ responses.' />
                <motion.i
                  onClick={handleSort}
                  name='factors.pain'
                  className='fa fa-caret-down'
                  initial={false}
                  animate={{
                    transform:
                      state.sorting.sort === 'asc' && 'factors.pain' === state.sorting.sortBy
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                  }}
                  style={findCaretStyles('factors.pain')}
                />
              </div>
            </div>
          )}

          {/*  F U N C T I O N A L I T Y  */}
          {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
            <div className='functionality-col' name='factors.functionality' onClick={handleSort}>
              <div
                style={hdrIsSelected('factors.functionality', labeledStyles.tableHeader.boxColor)}
              >
                <TableHeader
                  name='factors.functionality'
                  onClick={handleSort}
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  color={labeledStyles.tableHeader.color}
                  textTransform={labeledStyles.tableHeader.textTransform}
                  marginLeft={'25px'}
                >
                  {smallComputer || state.sorting.sortBy === 'factors.functionality'
                    ? 'Funct.'
                    : 'Functionality'}
                </TableHeader>
                <HoverDescription description='Functionality is derived from 2 questions about the degree of difficulty while rising from sitting and bending to the floor or picking up an object. The scores represent the percentile that a specific patient is in relative to other patients’ responses.' />
                <motion.i
                  onClick={handleSort}
                  name='factors.functionality'
                  className='fa fa-caret-down'
                  initial={false}
                  animate={{
                    transform:
                      state.sorting.sort === 'asc' &&
                        'factors.functionality' === state.sorting.sortBy
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                  }}
                  style={findCaretStyles('factors.functionality')}
                />
              </div>
            </div>
          )}

          {/* O R I G I N A L   B O O K I N G   (Rebooking & Already Rebooked Tabs)*/}
          {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
            <div
              className='days-from-surgery-col'
              name='factors.originalDos'
              onClick={handleSort}
            >
              <div
                style={hdrIsSelected('factors.originalDos', labeledStyles.tableHeader.boxColor)}
              >
                <TableHeader
                  name='factors.originalDos'
                  onClick={handleSort}
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  color={labeledStyles.tableHeader.color}
                  textTransform={labeledStyles.tableHeader.textTransform}
                  marginLeft={'25px'}
                >
                  {smallComputer || state.sorting.sortBy === 'factors.originalDos'
                    ? 'Orig. Booking'
                    : 'Original Booking'}
                </TableHeader>
                <motion.i
                  onClick={handleSort}
                  name='factors.originalDos'
                  className='fa fa-caret-down'
                  initial={false}
                  animate={{
                    transform:
                      state.sorting.sort === 'asc' &&
                        'factors.originalDos' === state.sorting.sortBy
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                  }}
                  style={findCaretStyles('factors.originalDos')}
                />
              </div>
            </div>
          )}

          {/* S U R G E R Y   D A T E ("Cancelled (Form Not Sent)" Tab)*/}
          {tabState.activeTab === 'Cancelled (Form Not Sent)' && (
            <div className='surgery-date-col' name='factors.dos'>
              <div style={hdrIsSelected('factors.surgeryDate', labeledStyles.tableHeader.boxColor)}>
                <TableHeader
                  name='factors.surgeryDate'
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  color={labeledStyles.tableHeader.color}
                  textTransform={labeledStyles.tableHeader.textTransform}
                  marginLeft={'15px'}
                >
                  Surgery Date
                </TableHeader>
              </div>
            </div>
          )}

          {/* D A Y S   F R O M   S U R G E R Y   (Just the number, no date) -> Cancelled (Form Not Sent) Tab */}
          {tabState.activeTab === 'Cancelled (Form Not Sent)' && (
            <div className='days-from-surgery-col' name='factors.daysFromSurgery'>
              <div>
                <TableHeader
                  name='factors.daysFromSurgery'
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  color={labeledStyles.tableHeader.color}
                  textTransform={labeledStyles.tableHeader.textTransform}
                >
                  Days From Surgery
                </TableHeader>
              </div>
            </div>
          )}

          {/* B M I */}
          {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
            <div className='bmi-col' name='factors.bmi' onClick={handleSort}>
              <div style={hdrIsSelected('factors.bmi', labeledStyles.tableHeader.boxColor)}>
                <TableHeader
                  name='factors.bmi'
                  onClick={handleSort}
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  color={labeledStyles.tableHeader.color}
                  textTransform={labeledStyles.tableHeader.textTransform}
                  marginLeft={'20px'}
                >
                  BMI
                </TableHeader>
                <motion.i
                  onClick={handleSort}
                  name='factors.bmi'
                  className='fa fa-caret-down'
                  initial={false}
                  animate={{
                    transform:
                      state.sorting.sort === 'asc' && 'factors.bmi' === state.sorting.sortBy
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                  }}
                  style={findCaretStyles('factors.bmi')}
                />
              </div>
            </div>
          )}

          {/* C O M O R B I D I T I E S */}
          {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
            <div className='comorbidities-col' name='factors.comorbidities' onClick={handleSort}>
              <div
                style={hdrIsSelected('factors.comorbidities', labeledStyles.tableHeader.boxColor)}
              >
                <TableHeader
                  name='factors.comorbidities'
                  onClick={handleSort}
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  color={labeledStyles.tableHeader.color}
                  textTransform={labeledStyles.tableHeader.textTransform}
                  marginLeft={'40px'}
                >
                  {smallerComputer ? 'Comor.' : 'Comorb.'}
                </TableHeader>
                <motion.i
                  onClick={handleSort}
                  name='factors.comorbidities'
                  className='fa fa-caret-down'
                  initial={false}
                  animate={{
                    transform:
                      state.sorting.sort === 'asc' &&
                        'factors.comorbidities' === state.sorting.sortBy
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                  }}
                  style={findCaretStyles('factors.comorbidities')}
                />
              </div>
            </div>
          )}

          {/* C O V I D */}
          {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
            <div className='covid-col' name='factors.covid' onClick={handleSort}>
              <div style={hdrIsSelected('factors.covid', labeledStyles.tableHeader.boxColor)}>
                <TableHeader
                  name='factors.covid'
                  onClick={handleSort}
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  color={labeledStyles.tableHeader.color}
                  textTransform={labeledStyles.tableHeader.textTransform}
                  marginLeft={'40px'}
                >
                  Covid
                </TableHeader>
                <motion.i
                  onClick={handleSort}
                  name='factors.covid'
                  className='fa fa-caret-down'
                  initial={false}
                  animate={{
                    transform:
                      state.sorting.sort === 'asc' && 'factors.covid' === state.sorting.sortBy
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                  }}
                  style={findCaretStyles('factors.covid')}
                />
              </div>
            </div>
          )}

          {/* P O S T   O P   C A R E */}
          {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
            <div className='post-op-col' name='factors.postOp' onClick={handleSort}>
              <div style={hdrIsSelected('factors.postOp', labeledStyles.tableHeader.boxColor)}>
                <TableHeader
                  name='factors.postOp'
                  onClick={handleSort}
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  color={labeledStyles.tableHeader.color}
                  marginLeft={'15px'}
                  textTransform={labeledStyles.tableHeader.textTransform}
                >
                  {smallComputer || state.sorting.sortBy === 'factors.postOp'
                    ? 'Post Op'
                    : 'Post-Op Care'}
                </TableHeader>
                <motion.i
                  onClick={handleSort}
                  name='factors.postOp'
                  className='fa fa-caret-down'
                  initial={false}
                  animate={{
                    transform:
                      state.sorting.sort === 'asc' && 'factors.postOp' === state.sorting.sortBy
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)'
                  }}
                  style={findCaretStyles('factors.postOp')}
                />
              </div>
            </div>
          )}

          {/* R E B O O K    S T A T U S */}
          {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
            <div className='status-col' name='factors.status'>
              <div style={hdrIsSelected('factors.status', labeledStyles.tableHeader.boxColor)}>
                <TableHeader
                  name='factors.status'
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  color={labeledStyles.tableHeader.color}
                  textTransform={labeledStyles.tableHeader.textTransform}
                >
                  {'Status & Actions'}
                </TableHeader>
              </div>
            </div>
          )}

          {/*  S E N D   S S   F O R M  */}
          {tabState.activeTab === 'Cancelled (Form Not Sent)' && (
            <div className='send-form-col'>
              <div style={hdrIsSelected('factors.send-form', labeledStyles.tableHeader.boxColor)}>
                <TableHeader
                  name='factors.send-form'
                  fontFamily={labeledStyles.tableHeader.fontFamily}
                  fontSize={labeledStyles.tableHeader.fontSize}
                  fontWeight={labeledStyles.tableHeader.fontWeight}
                  color={labeledStyles.tableHeader.color}
                  textTransform={labeledStyles.tableHeader.textTransform}
                >
                  {'Send Intake Form'}
                </TableHeader>
              </div>
            </div>
          )}
        </TableHeaderDiv>

        <hr style={{ marginBottom: 0, border: labeledStyles.table.hrBorder }} />
        <div
          ref={infiniteRef}
          style={{
            position: 'relative',
            boxShadow: labeledStyles.table.boxShadow,
            borderRadius: labeledStyles.table.borderRadius
          }}
        >
          {state.isLoading && (
            <div id='table-loading'>
              <LoadingSplash />
            </div>
          )}

          {
            tabState.activeTab === 'Rebooking' ?
              (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="table">
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        style={{ width: "100%", height: "100% " }}
                        {...provided.droppableProps}>
                        {patients.map((patient, idx) => (
                          <Draggable
                            draggableId={patient.calendarId.toString()}
                            index={idx}
                            key={patient.calendarId}
                          >
                            {(provided, snapshot) => (
                              <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >{renderRow(patient, idx, snapshot)}</div>
                            )}
                          </Draggable>
                        ))
                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) :
              patients.map((patient, idx) => renderRow(patient, idx))}
        </div>
        {(tabState.activeTab !== 'Cancelled (Form Not Sent)' ? state?.patients?.length > 0 : state?.cancelledCases?.length > 0) && state.isLoading && (
          <div className='loading-more-patients'>
            <LoadingSplash />
          </div>
        )}
      </TableDiv>
    )
  else return <div id='table' role='table' aria-label='Patient Priority' />
}

const TableHeaderDiv = styled.div`
  display: flex;
  background-color: ${(props) => props.backgroundColor};
  position: relative;
  .draggable-button {
    width: 25px;
    position: absolute;
    top: 0px;
    left: 4px;
    cursor: pointer;
  }
  h5:not(.physician-h5):not(.survey-h5) {
    cursor: ${(props) => props.cursor};
  }
`

const TableDiv = styled(motion.div)`
  padding-left: ${(props) => props.paddingLeft};
  padding-top: 10px;
  padding-right: 10px;
  margin-left: 0;
  padding-top: 50px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 20px;
`

const TableHeader = styled.h5`
  font-family: ${(props) => props.fontFamily};
  font-weight: ${(props) => props.fontWeight};
  margin: 0;
  margin-left: ${(props) => props.marginLeft};
  font-size: ${(props) => props.fontSize};
  padding-right: 5px;
  color: ${(props) => props.color};
  user-select: none;
  text-transform: ${(props) => props.textTransform};
  @media only screen and (max-width: 1650px) {
    font-size: 11px;
  }
`

export default Table
