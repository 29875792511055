import React, { useContext, useEffect } from 'react'
import { useMedia } from '../../hooks/useMedia'
import { Context, setScreenTooSmallAction } from '../../context/Context'
import './SmallScreenSplash.scss'

const SmallScreenSplash = () => {
	const {state, dispatch} = useContext(Context)
	const tooSmall = useMedia(`(max-width: 700px)`)

	useEffect(() => {
		tooSmall ? dispatch(setScreenTooSmallAction(true)) : dispatch(setScreenTooSmallAction(false))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tooSmall])

	const handleExitClick = () => {
		dispatch(setScreenTooSmallAction(false))
	}


	let splashStyles
	const findSplashStyles = () => {
		if(state.screenTooSmall) {
			splashStyles = {
				width: '100vw',
				height: '100vh',
				backgroundColor: 'var(--blueDark)',
				zIndex: 100,
				position: 'absolute',
				opacity: .8
			}
		} else {
			splashStyles = { display: 'none' }
		}
		return splashStyles
	}

	return (
		<>
			<div id='small-screen-background' style={findSplashStyles()}>
			</div>
			{state.screenTooSmall && (
				<div id='words' style={tooSmall ? {display:'block'} : {display: 'none'}}>
					<div>
					<img onClick={handleExitClick} className='exit'  src='../../images/exit-white.svg' alt='exit modal' />
						<p>For a better experience,</p>
						<p id='bold'>Please use a larger screen</p>
						<p style={{paddingBottom: '5px'}}>( or enlarge your browser window )</p>
					</div>
				</div>
			)}
		</>
	);
}
 
export default SmallScreenSplash;