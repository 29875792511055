import React, { useReducer } from 'react'
import {
  getConfigApi,
  getPoliciesApi,
  getPolicyPreferenceApi,
  savePolicyPreferenceApi,
  getPatientPrioritiesApi,
  getCancelledCasesApi,
  setPatientPriorityApi,
  getPhysiciansApi,
  getSurveyApi,
  getAvailabilityApi,
  getHeatCalendarAvailabilityApi
} from '../api/api'
import { configData } from '../api/config'

const reducer = (state, action) => {
  let result = {
    ...state
  }
  switch (action.type) {
    // Get Config
    case 'get-config':
      result.config = action.payload
      break
    case 'set-loading-true':
      result.isLoading = true
      break
    case 'set-error':
      result.isLoading = false
      console.log('ERROR:', action.payload)
      break
    // H A N D L E R S
    // Get Policies
    case 'get-policies-finish':
      result.isLoading = false
      result.policies = action.payload
      break

    // Get Policy Preference
    case 'get-policy-preference-finish':
      result.isLoading = false
      result.policyPreference = action.payload
      break

    // First Load of policies & policyPreference
    case 'first-load-start':
      result.isLoading = true
      result.isLoaded = false
      break
    case 'first-load-finish':
      result.isLoading = false
      result.isLoaded = true
      result.policies = action.payload.policies
      result.policyPreference = action.payload.policyPreference.id
      break

    // PUT Policy Preference
    case 'save-policy-preference-start':
      result.isLoading = true
      result.policyPreference = action.payload
      break
    case 'save-policy-preference-finish':
      result.patientsNextPageUri = null
      break
    case 'save-policy-preference-error':
      result.isLoading = false
      result.error = action.payload.error
      result.policyPreference = action.payload.oldPolicyId
      break

    //GET Patients
    case 'get-patient-priorities-finish':
      if (state.patients && action.payload.altered === false) {
        let gatheredPatients = state.patients
        for (let newPatient of action.payload.patients) {
          const found = state.patients.find((patient) => newPatient.id === patient.id)
          if (!found) {
            gatheredPatients.push(newPatient)
          }
        }
        result.isLoading = false
        result.patients = gatheredPatients
        result.patientsNextPageUri = action.payload.nextPageUri
        break
      } else {
        result.isLoading = false
        result.patients = action.payload.patients
        result.patientsNextPageUri = action.payload.nextPageUri
        break
      }
    case 'get-patient-priorities-nextpage-finish':
      let gatheredPatients = state.patients
      for (let newPatient of action.payload.patients) {
        const found = state.patients.find((patient) => newPatient.id === patient.id)
        if (!found) {
          gatheredPatients.push(newPatient)
        }
      }
      result.isLoading = false
      result.patients = gatheredPatients
      result.patientsNextPageUri = action.payload.nextPageUri
      break

    // Get Cancelled Cases
    case 'get-cancelled-cases-finish':
      result.isLoading = false
      result.cancelledCases = action.payload.cancelledCases
      result.cancelledCasesNextPageUri = action.payload.nextPageUri
      break
    case 'get-cancelled-cases-nextpage-finish':
      let gatheredCases = state.cancelledCases
      for (let newCase of action.payload.cancelledCases) {
        const found = state.cancelledCases.find((patient) => newCase.id === patient.id)
        if (!found) {
          gatheredCases.push(newCase)
        }
      }
      result.isLoading = false
      result.cancelledCases = gatheredCases
      result.cancelledCasesNextPageUri = action.payload.nextPageUri
      break

    //Save patient priority
    case 'set-patient-priority-start':
      result.isLoading = true
      result.reprioritizedPatient = action.payload
      break
    case 'set-patient-priority-finish':
      result.isLoading = false
      result.reprioritizedPatient = null
      result.patients = action.payload.patients
      result.patientsNextPageUri = action.payload.nextPageUri
      break
    //  update patients
    case 'update-patients':
      result.patients = action.payload.patients
      break

    // GET shared calendar users (physicians)
    case 'get-physicians-finish':
      result.isLoading = false
      result.physicians = action.payload
      break

    // Set filters
    case 'set-physician-filter':
      result.filters.physician = action.payload
      result.patientsNextPageUri = null // explicitly nullifying based on filter change
      break
    case 'set-priority-filter':
      result.filters.priority = action.payload
      result.patientsNextPageUri = null // explicitly nullifying based on filter change
      break
    case 'set-status-filter':
      result.filters.status = action.payload
      result.patientsNextPageUri = null // explicitly nullifying based on filter change
      break
    // Set sorting
    case 'set-sort-by':
      if (Array.isArray(action.payload) && action.payload.length === 0) {
        result.sorting.sortBy = 'priority.priority'
      } else {
        result.sorting.sortBy = action.payload
      }
      result.patientsNextPageUri = null // explicitly nullifying based on sort change
      break
    case 'set-sort-direction':
      result.sorting.sort = action.payload
      result.patientsNextPageUri = null // explicitly nullifying based on sort change
      break

    // Get patient survey
    case 'get-survey-finish':
      result.isLoading = false
      result.survey = action.payload
      break

    // Get Physians availability
    case 'get-availability-finish':
      result.isLoading = false
      result.availability = action.payload.availability
      break

    case 'get-heat-calendar-availability-finish':
      result.isLoading = false
      result.heatCalendarAvailability = action.payload
      break

    case 'set-survey-name':
      result.surveyName = action.payload
      break

    // Open/Closed
    case 'set-survey-modal':
      result.isSurveyOpen = action.payload
      break
    case 'set-confirmation-modal':
      result.isConfirmationOpen = action.payload
      break
    case 'set-rebook-modal-open':
      result.isRebookModalOpen = action.payload
      break
    case 'set-rebook-info':
      result.rebookInfo = action.payload
      break
    case 'set-policy-picker-open':
      result.isPolicyPickerOpen = action.payload
      break
    case 'set-screen-too-small':
      result.screenTooSmall = action.payload
      break

    case 'set-notification':
      result.notification = action.payload
      break

    case 'reset-next-link':
      result.patientsNextPageUri = null // explicitly nullifying next url
      break
        
    case 'array-of-patients-to-send-form':
      result.arrayOfPatientsToSendForm = action.payload
      break

    default:
      return result
  }
  return result
}

const initialResponseState = {
  isLoaded: false,
  isLoading: false,
  error: null,
  policies: null,
  policyPreference: null,
  patientsPageSize: 30,
  patients: null,
  patientsNextPageUri: null,
  cancelledCases: [],
  cancelledCasesNextPageUri: null,
  physicians: null,
  filters: {
    physician: [],
    priority: [],
    status: []
  },
  sorting: {
    sortBy: 'priority.priority',
    sort: 'desc'
  },
  survey: null,
  rebookInfo: null,
  isSurveyOpen: false,
  isRebookModalOpen: false,
  isPolicyPickerOpen: true,
  screenTooSmall: null,
  arrayOfPatientsToSendForm: []
}

const setLoadingTrue = () => ({ type: 'set-loading-true' })
const setError = () => ({ type: 'set-error' })
// A C T I O N S
//Get Policy
const getPoliciesFinishAction = (policies) => ({
  type: 'get-policies-finish',
  payload: policies
})
//Get Policy Preference
const getPolicyPreferenceFinishAction = (policy) => ({
  type: 'get-policy-preference-finish',
  payload: policy
})
//First Load
const firstLoadStartAction = () => ({ type: 'first-load-start' })
const firstLoadFinishAction = (policies, policyPreference) => ({
  type: 'first-load-finish',
  payload: { policies: policies, policyPreference: policyPreference }
})

// Save Policy Preference
const savePolicyPreferenceStartAction = (policyId) => ({
  type: 'save-policy-preference-start',
  payload: policyId
})
const savePolicyPreferenceFinishAction = () => ({ type: 'save-policy-preference-finish' })

const resetNextLinkAction = () => ({ type: 'reset-next-link' })

const savePolicyPreferenceErrorAction = (error, oldPolicyId) => ({
  type: 'save-policy-preference-error',
  payload: { error: error, oldPolicyId: oldPolicyId }
})

//Get Patients
const getPatientPrioritiesFinishAction = (patients, nextPageUri, altered) => ({
  type: 'get-patient-priorities-finish',
  payload: { patients: patients, nextPageUri: nextPageUri, altered: altered }
})
const getNextPagePatientPrioritiesFinishAction = (patients, nextPageUri) => ({
  type: 'get-patient-priorities-nextpage-finish',
  payload: { patients: patients, nextPageUri: nextPageUri }
})

//Get Cancelled Patients
const getCancelledCasesFinishAction = (cancelledCases, nextPageUri) => ({
  type: 'get-cancelled-cases-finish',
  payload: { cancelledCases: cancelledCases, nextPageUri: nextPageUri }
})
const getNextPageCancelledCasesFinishAction = (cancelledCases, nextPageUri) => ({
  type: 'get-cancelled-cases-nextpage-finish',
  payload: { cancelledCases: cancelledCases, nextPageUri: nextPageUri }
})

// Set Patients
const setPatientPriorityStartAction = (patientId) => ({
  type: 'set-patient-priority-start',
  payload: patientId
})
const setPatientPriorityFinishAction = (patients, nextPageUri) => ({
  type: 'set-patient-priority-finish',
  payload: { patients: patients, nextPageUri: nextPageUri }
})
const setPatientsFinishAction = (patients) => ({
  type: 'update-patients',
  payload: { patients: patients }
})

// Get shared calendar users (physicians)
const getPhysiciansFinishAction = (response) => ({
  type: 'get-physicians-finish',
  payload: response
})

// set physician filter
const setPhysicianAction = (physician) => ({
  type: 'set-physician-filter',
  payload: physician
})
// set priority filter
const setPriorityAction = (priority) => ({
  type: 'set-priority-filter',
  payload: priority
})
// set status filter
const setStatusAction = (status) => {
  return {
    type: 'set-status-filter',
    payload: status
  }
}

// Set sorting
const setSortByAction = (sortBy) => ({
  type: 'set-sort-by',
  payload: sortBy
})
const setSortDirectionAction = (sort) => ({
  type: 'set-sort-direction',
  payload: sort
})

// Get survey
const getSurveyFinishAction = (response) => ({
  type: 'get-survey-finish',
  payload: response
})

const setSurveyNameAction = (name) => ({
  type: 'set-survey-name',
  payload: name
})

// Get Physician's Availability
const getAvailabilityFinishAction = (string) => ({
  type: 'get-availability-finish',
  payload: string
})

const getHeatCalendarAvailabilityFinishAction = (string) => ({
  type: 'get-heat-calendar-availability-finish',
  payload: string
})

// Open/closed state
const setSurveyModalAction = (boolean) => ({
  type: 'set-survey-modal',
  payload: boolean
})
const setConfirmationModalAction = (boolean) => ({
  type: 'set-confirmation-modal',
  payload: boolean
})
const setRebookModalOpen = (boolean) => {
  return {
    type: 'set-rebook-modal-open',
    payload: boolean
  }
}
const setPolicyPickerOpenAction = (boolean) => ({
  type: 'set-policy-picker-open',
  payload: boolean
})
const setScreenTooSmallAction = (boolean) => ({
  type: 'set-screen-too-small',
  payload: boolean
})

const setRebookInfo = (object) => ({
  type: 'set-rebook-info',
  payload: object
})

const setNotification = (string) => ({
  type: 'set-notification',
  payload: string
})

// To send form to
const saveArrayOfPatientsToSendForm = (arrayOfPatients) => ({
  type: 'array-of-patients-to-send-form',
  payload: arrayOfPatients
})

// A P I  F U N C T I O N S
/**
 * Get Policies action
 * @param state
 * @param dispatch
 */

const getConfig = (dispatch) => {
  getConfigApi().then(
    (response) => {
      if (response.data) {
        dispatch({ type: 'get-config', payload: response })
      }
    },
    (error) => {
      // fallback for Dev environment
      if (configData) {
        dispatch({ type: 'get-config', payload: configData })
      }
    }
  )
}

const getPolicies = (state, dispatch) => {
  dispatch(setLoadingTrue())
  getPoliciesApi()
    .then((response) => {
      dispatch(getPoliciesFinishAction(response))
    })
    .catch((e) => {
      dispatch(setError(e))
    })
}

/**
 * Get Policy Preference action
 * @param state
 * @param dispatch
 */
const getPolicyPreference = (state, dispatch) => {
  dispatch(setLoadingTrue())
  getPolicyPreferenceApi()
    .then((response) => {
      dispatch(getPolicyPreferenceFinishAction(response))
    })
    .catch((e) => {
      dispatch(setError(e))
    })
}

const firstLoad = (state, dispatch) => {
  dispatch(firstLoadStartAction())
  Promise.all([getPoliciesApi(), getPolicyPreferenceApi()])
    .then((values) => {
      // values[0] => getPoliciesApi, values[1] => getPolicyPreferenceApi
      dispatch(firstLoadFinishAction(values[0], values[1]))
    })
    .catch((e) => {
      dispatch(setError(e))
    })
}

// SAVE POLICY PREFERENCE COMPOSITE FUNCTION
const savePolicyPreference = (policyId, state, dispatch) => {
  let oldPreference = state.policyPreference
  dispatch(savePolicyPreferenceStartAction(policyId))
  savePolicyPreferenceApi(policyId)
    .then(() => {
      dispatch(savePolicyPreferenceFinishAction())
    })
    .then(() => {
      getPatientPrioritiesApi(
        policyId,
        state.filters.physician,
        state.filters.priority,
        state.filters.surveyCompletion,
        state.filters.status,
        state.patientsPageSize,
        null, // ususally, state.patientsNextPageUri but needs to be null in this case
        state.sorting.sortBy,
        state.sorting.sort
      )
        .then(({ json, nextPageUri }) => {
          dispatch(setPatientPriorityFinishAction(json, nextPageUri))
        })
        .catch((e) => {})
    })

    .catch((e) => {
      dispatch(savePolicyPreferenceErrorAction(e, oldPreference))
    })
}

/**
 * Get Patients action
 * @param state
 * @param dispatch
 */
// GET PATIENTS API FUNCTIONS
const getPatientPriorities = (state, dispatch, altered, tab) => {
  dispatch(setLoadingTrue())
  getPatientPrioritiesApi(
    state.policyPreference,
    state.filters.physician,
    state.filters.priority,
    state.filters.surveyCompletion,
    state.filters.status,
    state.patientsPageSize,
    (altered ? state.patientsNextPageUri : null),
    state.sorting.sortBy,
    state.sorting.sort,
    tab,
  )
    .then(({ json, nextPageUri }) => {
      dispatch(getPatientPrioritiesFinishAction(json, nextPageUri, altered))
    })
    .catch((e) => {
      dispatch(setError(e))
    })
}

const getNextPagePatientPriorities = (state, dispatch) => {
  dispatch(setLoadingTrue())
  getPatientPrioritiesApi(
    state.policyPreference,
    state.filters.physician,
    state.filters.priority,
    state.filters.surveyCompletion,
    state.filters.status,
    state.patientsPageSize,
    state.patientsNextPageUri
  )
    .then(({ json, nextPageUri }) => {
      dispatch(getNextPagePatientPrioritiesFinishAction(json, nextPageUri))
    })
    .catch((e) => {
      dispatch(setError(e))
    })
}

const getCancelledCases = (state, dispatch) => {
  dispatch(setLoadingTrue())
  if (state.filters.physician.length <= 1) {
    let allPhysicianIds = []
    for (let i = 0; i < state.physicians.length; i++) {
      allPhysicianIds.push(state.physicians[i].id)
    }
    getCancelledCasesApi(allPhysicianIds, state.patientsPageSize)
      .then(({ json, nextPageUri }) => {
        dispatch(getCancelledCasesFinishAction(json, nextPageUri))
      })
      .catch((e) => {
        dispatch(setError(e))
      })
  } else {
    getCancelledCasesApi(
      state.filters.physician,
      state.patientsPageSize,
    )
      .then(({ json, nextPageUri }) => {
        dispatch(getCancelledCasesFinishAction(json, nextPageUri))
      })
      .catch((e) => {
        dispatch(setError(e))
      })
  }
}

const getNextPageCancelledCases = (state, dispatch) => {
  dispatch(setLoadingTrue())
  if (state.filters.physician.length <= 1 ) {
    let allPhysicianIds = []
    for (let i = 0; i < state.physicians.length; i++) {
      allPhysicianIds.push(state.physicians[i].id)
    }
    getCancelledCasesApi(allPhysicianIds, state.patientsPageSize, state.cancelledCasesNextPageUri)
      .then(({ json, nextPageUri }) => {
        dispatch(getNextPageCancelledCasesFinishAction(json, nextPageUri))
      })
      .catch((e) => {
        dispatch(setError(e))
      })
  } else {
    getCancelledCasesApi(
      state.filters.physician,
      state.patientsPageSize,
      state.cancelledCasesNextPageUri
    )
      .then(({ json, nextPageUri }) => {
        dispatch(getNextPageCancelledCasesFinishAction(json, nextPageUri))
      })
      .catch((e) => {
        dispatch(setError(e))
      })
  }
}

const setPatientPriority = (state, dispatch, patientId, priority) => {
  dispatch(setPatientPriorityStartAction(patientId))
  setPatientPriorityApi(patientId, priority, state.policyPreference)
    .then((response) => {
      getPatientPrioritiesApi(
        state.policyPreference,
        state.filters.physician,
        state.filters.priority,
        state.filters.status,
        state.filters.surveyCompletion,
        state.patientsPageSize,
        null, // ususally, state.patientsNextPageUri but needs to be null in this case
        state.sorting.sortBy,
        state.sorting.sort
      )
        .then(({ json, nextPageUri }) => {
          dispatch(setPatientPriorityFinishAction(json, nextPageUri))
        })
        .catch((e) => {})
    })
    .catch((e) => {
      dispatch(setError(e))
    })
}

// Get shared calendar users (physicians)
const getPhysicians = (state, dispatch) => {
  dispatch(setLoadingTrue())
  getPhysiciansApi()
    .then((response) => {
      dispatch(getPhysiciansFinishAction(response))
    })
    .catch((e) => {
      dispatch(setError(e))
    })
}

const getSurvey = (state, dispatch, recipientId) => {
  getSurveyApi(recipientId)
    .then((response) => {
      dispatch(getSurveyFinishAction(response))
    })
    .catch((e) => {
      dispatch(setError(e))
    })
}

const getAvailability = (physician, date, dispatch) => {
  dispatch(setLoadingTrue())
  getAvailabilityApi(physician, date)
    .then((response) => {
      dispatch(getAvailabilityFinishAction(response))
    })
    .catch((e) => {
      dispatch(setError(e))
    })
}

const getHeatCalendarAvailability = (start, end, physician, dispatch) => {
  dispatch(setLoadingTrue())
  getHeatCalendarAvailabilityApi(start, end, physician)
    .then((response) => {
      dispatch(getHeatCalendarAvailabilityFinishAction(response))
    })
    .catch((e) => {
      dispatch(setError(e))
    })
}

const resetNextLink = (dispatch) => {
  dispatch(resetNextLinkAction())
}

const setArrayOfPatientsToSendForm = (recentlyToggledCase, isIncluded, arrayState, dispatch) => {
  // gather calendarIds of selected cases (on Cancelled (Form Not Sent) Tab)
  let updatedArray = arrayState
  if (isIncluded) {
    updatedArray = [...updatedArray, recentlyToggledCase.calendarId]
  } else {
    updatedArray = arrayState.filter((id) => id !== recentlyToggledCase.calendarId)
  }
  dispatch(saveArrayOfPatientsToSendForm(updatedArray))
}

const setArrayOfPatientsToSendFormToAll = (allCancelledCases, dispatch) => {
  const allCancelledIds = allCancelledCases.map((item) => item.calendarId)
  dispatch(saveArrayOfPatientsToSendForm(allCancelledIds))
}

const clearArrayOfPatientsToSendForm = (dispatch) => {
  dispatch(saveArrayOfPatientsToSendForm([]))
}
const setUpdatePatients = (dispatch, patients) => {
  dispatch(setPatientsFinishAction(patients))
}

const initState = () => initialResponseState
const Context = React.createContext({})

function ContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, undefined, initState)

  return <Context.Provider value={{ state, dispatch }}>{props.children}</Context.Provider>
}

export {
  Context,
  ContextProvider,
  firstLoad,
  getConfig,
  getPolicies,
  getPolicyPreference,
  getPatientPriorities,
  getNextPagePatientPriorities,
  getCancelledCases,
  getNextPageCancelledCases,
  setPatientPriority,
  getPhysicians,
  getSurvey,
  setUpdatePatients,
  setPhysicianAction,
  savePolicyPreference,
  setPriorityAction,
  setStatusAction,
  setSortByAction,
  setSortDirectionAction,
  setSurveyNameAction,
  setSurveyModalAction,
  setConfirmationModalAction,
  setRebookModalOpen,
  setPolicyPickerOpenAction,
  setScreenTooSmallAction,
  setRebookInfo,
  getAvailability,
  getHeatCalendarAvailability,
  setNotification,
  resetNextLink,
  setArrayOfPatientsToSendForm,
  setArrayOfPatientsToSendFormToAll,
  clearArrayOfPatientsToSendForm
}
