import React, { useRef, useEffect, useContext } from 'react'
import { Context, getSurvey, setSurveyModalAction, setSurveyNameAction } from '../../context/Context'
import moment from 'moment'
import { unescape } from 'lodash'
import './SurveyResponses.scss'
import { colors, labeledStyles } from '../../styles'

const SurveyResponses = ({ patientId }) => {
  const { state, dispatch } = useContext(Context)

  // Close dropdown if user clicks outside of it
  const node = useRef(null)

  // grab the survey Id from the component props
  useEffect(() => {
    getSurvey(state, dispatch, patientId)
    dispatch(setSurveyNameAction(''))
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.current])

  const handleOutsideClick = (e) => {
    if (node.current) {
      if (node.current.contains(e.target)) {
        return
      }
    }
    dispatch(setSurveyModalAction(false))
  }

  const findDisplayedResponse = (q) => {
    let display

    switch (true) {
      case q.input.type === 'availability':
        let dates = []
        if (q.response) {
          let sortedDates = q.response.sort((a, b) => {
            return moment(a).diff(b)
          })
          sortedDates.forEach((date) => {
            if (date === false) return
            dates.push(moment(date).format('MMMM Do YYYY'))
          })
          if (dates.length) {
            display = dates.join('\r\n')
          } else {
            display = 'No dates were chosen.'
          }
        }
        break
      case q.input.type === 'boolean' || q.input.type === 'enumRadio':
        q.input.options.enums.forEach((o) => {
          if (q.response === o.value) {
            display = o.display
          }
        })
        break
      case q.input.type === 'enumDropdown':
        q.input.options.enums.forEach((o) => {
          if (parseInt(q.response) === o.value) {
            display = o.display
          }
        })
        break
      case q.input.type === 'enumCheckBox':
        let array = []
        q.response &&
        q.response.forEach((r) => {
          q.input.options.enums.forEach((o) => {
            if (o.value === r) {
              array.push(o.display)
              display = array.join(', ')
            }
          })
        })
        break
      case q.input.type === 'numInput':
        if (q.input.options.dimension === 'weight') {
          // convert from kg to lbs
          display = (parseFloat(q.response) * 2.2046).toFixed(1) + ' lbs'
        } else if (q.input.options.dimension === 'height') {
          // convert from cm to ft + inches
          let ft = (q.response * 0.3937) / 12
          let feet = Math.floor(ft)
          let inches = Math.round((ft - feet) * 12)
          if (inches === 12) {
            feet += 1
            inches = 0
          }
          display = `${feet}' ${inches}"`
        } else {
          display = q.response
        }
        break
      case q.input.type === 'textInput':
          display = q.response
        break
      case q.input.type === 'detailedListModal':
          if(Array.isArray(q.input.options.objItems)) {
            // let keys = q.input.options.objItems.map(x => x.name.split(' ').join('_'))
            if(Array.isArray(q.response)) {
              display = q.response.map(x => Object.values(x).join(' - ')).join('\r\n')
            }
          }
        break
      case q.input.type === 'rangeBar' || q.input.type === 'textBox':
        display = q.response
        break
      default:
        return display
    }
    return display
  }

  return (
    <div id="modal-component">
      <div id="background" style={{ backgroundColor: colors.lightGrey }}></div>
      <div
        className="survey-responses-div"
        ref={node}
        style={{ borderRadius: labeledStyles.modal.borderRadius }}
      >
        <div className="responses-content" id="modal">
          {/* header */}
          <div
            id="modal-header"
            style={{
              height: 60,
              backgroundColor: labeledStyles.modal.headerBackground,
              padding: 15,
              zIndex: 1000,
              borderRadius: '10px 10px 0 0',
              borderBottom: labeledStyles.modal.headerBorderBottom
            }}
          >
            <div className="left-info">
              <h3
                style={{
                  fontFamily: labeledStyles.modal.h3.fontFamily,
                  fontSize: labeledStyles.modal.h3.fontSize,
                  color: labeledStyles.modal.h3.color,
                  fontWeight: 600
                }}
              >
                Patient Intake Results
              </h3>
              <p
                style={{
                  fontFamily: labeledStyles.modal.p.fontFamily,
                  fontWeight: 400
                }}
              >
                {state.surveyName}
              </p>
            </div>
          </div>
          <div id="survey-content">
            {state.survey ? (
              state.survey.map((q, idx) => (
                <div key={idx} className="question">
                  <h4>
                    {idx + 1}. {unescape(q.prompt)}
                  </h4>
                  <p
                    style={{
                      whiteSpace: q.input.type === 'availability' ? 'pre' : 'normal',
                      fontFamily: labeledStyles.modal.p.fontFamily,
                      fontWeight: labeledStyles.modal.p.fontWeight,
                      color: labeledStyles.modal.p.color,
                      marginTop: 5
                    }}
                  >
                    {findDisplayedResponse(q)}
                  </p>
                </div>
              ))
            ) : (
              <div className="no-results">
                <span>No Intake Results to Show</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SurveyResponses
