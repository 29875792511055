import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'

import {
  Context,
  getSurvey,
  setRebookInfo,
  setPatientPriority,
  setRebookModalOpen,
  setSurveyNameAction,
  setSurveyModalAction,
  setArrayOfPatientsToSendForm
} from '../../context/Context'

import Toggle from './Toggle'
import { useMedia } from '../../hooks/useMedia'
import { useHover } from '../../hooks/useHover'
import StatusTool from '../StatusTool/StatusTool'
import { Context as TabContext } from '../../context/TabContext'
import PrioritySelector from '../PrioritySelector/PrioritySelector'

import CovidIcon from '../../svg/CovidIcon'
import PostOpIcon from '../../svg/PostOpIcon'
import VelysCovidIcon from '../../svg/velys_icons/CovidIcon'
import ComorbiditiesIcon from '../../svg/ComborbiditiesIcon'
import { ReactComponent as Draggable } from './draggable.svg'
import VelysPostOpIcon from '../../svg/velys_icons/PostOpIcon'

import './Row.scss'
import { colors, labeledStyles, skin } from '../../styles'

const Row = ({
  index,
  patient,
  snapshot,
  prioritySelectorOffset,
  setPrioritySelectorOffset,
}) => {
  const { state, dispatch } = useContext(Context)
  const { state: tabState } = useContext(TabContext)
  const [isStatusToolOpen, setStatusToolOpen] = useState(false)
  const [localRebookInfo, setLocalRebookInfo] = useState()
  const [rowClicked, setRowClicked] = useState()
  let tabSize = useMedia('(max-width: 985px)')
  let [isSendFormChecked, setSendFormChecked] = useState(false)

  useEffect(() => {
    setArrayOfPatientsToSendForm(
      patient,
      isSendFormChecked,
      state.arrayOfPatientsToSendForm,
      dispatch
    )
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendFormChecked])

  useEffect(() => {
    if (state.arrayOfPatientsToSendForm.includes(patient.calendarId)) {
      setSendFormChecked(true)
    } else {
      setSendFormChecked(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.arrayOfPatientsToSendForm])


  useEffect(() => {
    if (state.survey && index === rowClicked) {
      setLocalRebookInfo({
        eventId: patient.calendarId,
        patientName: patient.name,
        caseName: patient.case,
        duration: patient.duration,
        availability:
          state.survey && state.survey.find((element) => element.input.type === 'availability'),
        i: index,
        physician: patient.provider.id
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.survey])

  useEffect(() => {
    if (localRebookInfo) {
      dispatch(setRebookModalOpen(true))
      dispatch(setRebookInfo(localRebookInfo))
      setRowClicked(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localRebookInfo])

  const handleViewIntakeClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    toggleStatusBox(e)
    dispatch(setSurveyModalAction(!state.isSurveyOpen))
    getSurvey(state, dispatch, patient.id)
    dispatch(setSurveyNameAction(patient.name))
  }

  const handleRebookClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setRebookModalOpen(true)
    setRowClicked(index)
    getSurvey(state, dispatch, patient.id)
    toggleStatusBox(e)
  }

  const toggleStatusBox = (e) => {
    setStatusToolOpen(!isStatusToolOpen)
  }

  const findFill = (priority) => {
    let fill
    if (priority == null) {
      fill = labeledStyles.prioritySelector.na
    } else if (priority >= 0.9) {
      fill = labeledStyles.prioritySelector.high
    } else if (priority < 0.9 && priority >= 0.1) {
      fill = labeledStyles.prioritySelector.medium
    } else if (priority < 0.1) {
      fill = labeledStyles.prioritySelector.low
    }
    return fill
  }

  let physician
  patient.provider && patient.provider.name && (physician = patient.provider.name)

  let dob
  patient.dob ? (dob = patient.dob) : (dob = 'N / A')
  let dobForCancelled //for Cancelled Tab
  let age // for every other tab
  if (dob !== 'N / A') {
    let years = moment(dob, 'YYYYMMDDhhmmss').fromNow().replace('years ago', '')
    dobForCancelled = `${moment(dob, 'YYYYMMDDhhmmss').format('MMM D, YYYY')}  (${years}y)`
    switch (true) {
      case years >= 0 && years < 10:
        age = tabSize ? `0-9(${years} y)` : ``
        break
      case years >= 10 && years < 20:
        age = `10-19 (${years} y)`
        break
      case years >= 20 && years < 30:
        age = `20-29 (${years} y)`
        break
      case years >= 30 && years < 40:
        age = `30-39 (${years} y)`
        break
      case years >= 40 && years < 50:
        age = `40-49 (${years}y)`
        break
      case years >= 50 && years < 60:
        age = `50-59 (${years}y)`
        break
      case years >= 60 && years < 70:
        age = `60-69 (${years} y)`
        break
      case years >= 70 && years < 80:
        age = `70-79 (${years} y)`
        break
      case years >= 80 && years < 90:
        age = `80-89 (${years} y)`
        break
      case years >= 90 && years < 100:
        age = `90-99 (${years} y)`
        break
      case years >= 100:
        age = `100+ (${years} y)`
        break
      default:
        age = 'N / A'
    }
  } else {
    age = 'N / A'
    dobForCancelled = 'N / A'
  }

  const findAgeColor = (age) => {
    if (age === 'N / A') {
      return 'var(--purpleGray)'
    }
  }

  let fallRisk
  let pain
  let functionality
  let originalBooking
  let bmi
  let comorbidities

  if (tabState.activeTab !== 'Cancelled (Form Not Sent)' && patient.factors) {
    patient.factors.fall_risk
      ? (fallRisk = (parseFloat(patient.factors.fall_risk) * 100).toFixed(2).toString() + '%')
      : (fallRisk = 'N / A')

    patient.factors.pain
      ? (pain = (parseFloat(patient.factors.pain) * 100).toFixed(2).toString() + '%')
      : (pain = 'N / A')

    patient.factors.functionality
      ? (functionality =
        (parseFloat(patient.factors.functionality) * 100).toFixed(2).toString() + '%')
      : (functionality = 'N / A')

    patient.originalDos ? (originalBooking = patient.originalDos) : (originalBooking = 'N / A')

    patient.factors.bmi ? (bmi = patient.factors.bmi) : (bmi = 'N / A')

    patient.factors.comorbidities
      ? (comorbidities = patient.factors.comorbidities.length)
      : (comorbidities = 'N / A')
  }

  // Pain
  const ifAbove90 = (factor) => {
    if (parseInt(factor) > 90) {
      return 'var(--red)'
    } else if (factor === 'N / A') {
      return 'var(--purpleGray)'
    }
  }

  // Functionality
  const ifBelow10 = (factor) => {
    if (factor < 10) {
      return 'var(--red)'
    } else if (factor === 'N / A') {
      return 'var(--purpleGray)'
    }
  }

  // Original Booking
  const ifInPast = (originalBooking) => {
    if (moment(originalBooking).isBefore(moment())) {
      return 'var(--red)'
    } else if (originalBooking === 'N / A' || originalBooking === null) {
      return 'var(--purpleGray)'
    }
  }

  const findDisplay = (originalBooking) => {
    const today = moment()
    const originalDate = moment(originalBooking, "YYYYMMDDhhmmss")
    let difference = originalDate.diff(today, 'days')
    if (difference === 1) {
      return `${Math.abs(difference)} day until ${originalDate.format('M/D/YY')}`
    } else if (difference > 1) {
      return `${Math.abs(difference)} days until ${originalDate.format('M/D/YY')}`
    } else if (difference === 0) {
      return `${Math.abs(difference)} days until ${originalDate.format('M/D/YY')}`
    } else if (difference < -1) {
      return `${Math.abs(difference)} days since ${originalDate.format('M/D/YY')}`
    } else if (difference === -1) {
      return `${Math.abs(difference)} day since ${originalDate.format('M/D/YY')}`
    } else {
      return
    }
  }

  // MRN ("Cancelled (Form Not Sent)")
  let mrn
  patient.mrn ? (mrn = patient.mrn) : (mrn = 'N / A')

  // Date of Surgery ("Cancelled (Form Not Sent)" Tab)
  let dos
  patient.dos ? (dos = moment(patient.dos, "YYYYMMDDhhmmss").format('MMMM D, YYYY')) : (dos = 'N / A')

  // Days from Surgery ("Cancelled (Form Not Sent)" Tab)
  let daysFromSurgery
  if (patient.dos) {
    daysFromSurgery = findDisplay(patient.dos)
  } else {
    daysFromSurgery = 'N / A'
  }

  // BMI
  const ifAbove35 = (factor) => {
    if (parseInt(factor) >= 35) {
      return 'var(--red)'
    } else if (factor === 'N / A') {
      return 'var(--purpleGray)'
    }
  }

  // Comorbidities
  const ifNA = (factor) => {
    if (factor === 'N / A') return 'var(--purpleGray)'
  }
  let [comorbHoverRef, comorbIsHovered] = useHover()
  let [covidHoverRef, covidIsHovered] = useHover()
  let [postOpHoverRef, postOpIsHovered] = useHover()
  let [statusHoverRef] = useHover()

  const findRowStyles = (index) => {
    if (index === 0) {
      return {
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: '10px 10px 0 0',
        backgroundColor: 'white',
        opacity: snapshot?.isDragging ? 0.5 : 1,
        borderBottom: skin === 'velys' && `2px solid ${colors.superLightGrey}`
      }
    }
    if (index % 2 === 0) {
      return {
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: 'white',
        opacity: snapshot?.isDragging ? 0.5 : 1,
        borderBottom: skin === 'velys' && `2px solid ${colors.superLightGrey}`
      }
    } else {
      return {
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: labeledStyles.row.alternatingRowColor,
        opacity: snapshot?.isDragging ? 0.5 : 1,
        borderBottom: skin === 'velys' && `2px solid ${colors.superLightGrey}`
      }
    }
  }

  const [isDraggable, setDraggable] = useState()

  useEffect(() => {
    if (state.sorting.sortBy === 'priority.priority' && !tabSize) {
      setDraggable(true)
    } else {
      setDraggable(false)
    }
  }, [state.sorting.sortBy, tabSize])

  let dragDivStyle
  const findDragDivStyle = () => {
    if (isDraggable && !tabSize) {
      dragDivStyle = {
        cursor: 'grab',
        width: '25px'
      }
    } else if (tabSize) {
      dragDivStyle = {
        cursor: 'default',
        width: '10px'
      }
    } else if (!isDraggable && !tabSize) {
      dragDivStyle = {
        cursor: 'default',
        width: '15px'
      }
    }
    return dragDivStyle
  }

  const rowFontStyles = `
    fontFamily: ${labeledStyles.row.spanFont}, 
    fontWeight: ${labeledStyles.row.fontWeight},
    fontSize: ${labeledStyles.row.fontSize}
  `

  const VerticalLine = ({ index }) => {
    if (index === 0) {
      return (
        <div
          style={{
            height: '130%',
            width: '2px',
            backgroundColor: skin === 'velys' ? colors.superLightGrey : 'transparent',
            marginRight: 10,
            marginLeft: 5,
            marginTop: 30
          }}
        ></div>
      )
    } else {
      return (
        <div
          style={{
            height: '130%',
            width: '2px',
            backgroundColor: skin === 'velys' ? colors.superLightGrey : 'transparent',
            marginRight: 10,
            marginLeft: 5
          }}
        ></div>
      )
    }
  }

  const triggerOpenDocbox = (eventId) => {
    let targetOrigin
    console.log('window.location.href (triggerOpenDocbox()):', window.location.href)
    // LOCAL
    if (window.location.href.includes('docspera.localhost')) {
      targetOrigin = 'https://docspera.localhost'
      // STAGING (VELYS QA)
    }  else if (window.location.href.includes('velys.docvisor.com')) {
      targetOrigin = 'https://velys.docvisor.com'
      // STAGING (DocSpera)
    } else if (window.location.href.includes('docvisor.com')) {
      targetOrigin = 'https://docvisor.com'
      // STAGING (DocSpera x Velys) ??? DocVisor.com -> daryl+velyphysician@docspera.com
    } else if (window.location.href.includes('velys.docspera.dev')) {
      targetOrigin = 'https://velys.docspera.dev'
      // DocSpera DEV (?)
    } else if (window.location.href.includes('docspera.dev')) {
      targetOrigin = 'https://docspera.dev'
      // VELYS SIT
    } else if (window.location.href.includes('velys.docharp.com')) {
      targetOrigin = 'https://velys.docharp.com'
      // PRODUCTION DOCSPERA
    } else if (window.location.href.includes('docspera.com')) {
      targetOrigin = 'https://docspera.com'
      // PRODUCTION (VELYS)
    } else if (window.location.href.includes('carecoordination.velys.jnj')) {
      targetOrigin = 'https://carecoordination.velys.jnj'
    } else {
      console.log('no origin to send to')
      return
    }
    console.log('targetOrigin (triggerOpenDocBox()):', targetOrigin)
    let targetWindow = window.parent
    if (targetWindow) {
      targetWindow.postMessage({ type: 'eventId', payload: eventId }, targetOrigin)
    }
  }

  return (
    <div
      className='row'
      style={findRowStyles(index)}
      onClick={() => triggerOpenDocbox(patient.calendarId)}
    >
      {tabState.activeTab === 'Rebooking' && patient.priority && (
        <>
          <div className='priority-col'>
            <div className='drag-div' style={findDragDivStyle()}>
              {isDraggable && <Draggable width='12px' fill={findFill(patient.priority.priority)} />}
            </div>
            <div onClick={(e) => e.stopPropagation()}>
              <PrioritySelector
                value={patient.priority.priority}
                setValue={(priority) => setPatientPriority(state, dispatch, patient.id, priority)}
                offset={prioritySelectorOffset}
                setOffset={setPrioritySelectorOffset}
                highColor={labeledStyles.prioritySelector.high}
                mediumColor={labeledStyles.prioritySelector.medium}
                lowColor={labeledStyles.prioritySelector.low}
                naColor={labeledStyles.prioritySelector.na}
                fontFamily={labeledStyles.prioritySelector.fontFamily}
                loading={state.reprioritizedPatient === patient.id ? true : false}
              />
            </div>
          </div>
          <VerticalLine index={index} />
        </>
      )}
      <div style={tabState.activeTab !== 'Rebooking' ? { marginLeft: 16 } : {}}>
        <span className='physician-col' style={{ rowFontStyles }}>
          {physician}
        </span>
      </div>
      <VerticalLine index={index} />
      <span style={{ rowFontStyles }} className='patient-col'>
        {patient.name}
      </span>
      <VerticalLine index={index} />
      <span className='age-col' style={{ color: `${findAgeColor(age)}`, rowFontStyles }}>
        {tabState.activeTab === 'Cancelled (Form Not Sent)' ? dobForCancelled : age}
      </span>
      <VerticalLine index={index} />
      {tabState.activeTab === 'Cancelled (Form Not Sent)' && (
        <>
          <span className='mrn-col' style={{ rowFontStyles }}>
            {mrn}
          </span>
          <VerticalLine index={index} />
        </>
      )}

      {/*  Fall Risk  */}
      {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
        <>
          <span
            className='fall-risk-col'
            style={{ color: `${ifAbove90(fallRisk)}`, rowFontStyles }}
          >
            {fallRisk}
          </span>
          <VerticalLine index={index} />
        </>
      )}

      {/* Pain */}
      {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
        <>
          <span className='pain-col' style={{ color: `${ifAbove90(pain)}`, rowFontStyles }}>
            {pain}
          </span>
          <VerticalLine index={index} />
        </>
      )}

      {/* Functionality */}
      {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
        <>
          <span
            className='functionality-col'
            style={{ color: `${ifBelow10(functionality)}`, rowFontStyles }}
          >
            {functionality}
          </span>
          <VerticalLine index={index} />
        </>
      )}

      {/* Original Booking */}
      {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
        <>
          <span
            className='days-from-surgery-col'
            style={{ color: `${ifInPast(originalBooking)}`, rowFontStyles }}
          >
            {originalBooking !== 'N / A' ? findDisplay(originalBooking) : originalBooking}
          </span>
          <VerticalLine index={index} />
        </>
      )}

      {/* Surgery Date (Cancelled Tab)*/}
      {tabState.activeTab === 'Cancelled (Form Not Sent)' && (
        <>
          <span
            className='surgery-date-col'
            style={{ color: `${ifInPast(patient.dos)}`, rowFontStyles }}
          >
            {dos}
          </span>
          <VerticalLine index={index} />
        </>
      )}

      {/* Days From Surgery (Days only, no date) Cancelled Tab */}
      {tabState.activeTab === 'Cancelled (Form Not Sent)' && (
        <>
          <span
            className='days-from-surgery-col'
            style={{ color: `${ifInPast(patient.dos)}`, rowFontStyles }}
          >
            {daysFromSurgery}
          </span>
          <VerticalLine index={index} />
        </>
      )}

      {/* BMI */}
      {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
        <>
          <span className='bmi-col' style={{ color: `${ifAbove35(bmi)}`, rowFontStyles }}>
            {bmi}
          </span>
          <VerticalLine index={index} />
        </>
      )}

      {/* Comorbidities */}
      {tabState.activeTab !== 'Cancelled (Form Not Sent)' && patient.factors && (
        <>
          <div className='comorbidities-col'>
            <span style={{ color: `${ifNA(comorbidities)}`, rowFontStyles }}>{comorbidities}</span>

            {comorbidities !== 0 && comorbidities !== 'N / A' && (
              <div ref={comorbHoverRef} style={{ position: 'relative' }}>
                <ComorbiditiesIcon
                  color={
                    comorbIsHovered ? labeledStyles.row.hoverIconColor : labeledStyles.row.iconColor
                  }
                />
                {comorbIsHovered && (
                  <div className='comorb-list'>
                    <ul>
                      {patient.factors.comorbidities &&
                        patient.factors.comorbidities.map((comorb, idx) => {
                          return (
                            <li key={idx} style={{ rowFontStyles }}>
                              {comorb}
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
          <VerticalLine index={index} />
        </>
      )}

      {/* Covid */}
      {tabState.activeTab !== 'Cancelled (Form Not Sent)' && patient.factors && (
        <>
          <span className='covid-col'>
            {patient.factors.covid ? (
              <div ref={covidHoverRef} style={{ position: 'relative', width: '25px' }}>
                {skin === 'velys' ? (
                  <VelysCovidIcon />
                ) : (
                  <CovidIcon
                    color={
                      covidIsHovered
                        ? labeledStyles.row.hoverIconColor
                        : labeledStyles.row.iconColor
                    }
                  />
                )}
                {covidIsHovered && (
                  <p className='explanation'>
                    Patient's answers indicate{' '}
                    <span
                      style={{
                        fontWeight: '600',
                        color: labeledStyles.row.hoverIconColor
                      }}
                    >
                      possible infection
                    </span>
                  </p>
                )}
              </div>
            ) : (
              '--'
            )}
          </span>
          <VerticalLine index={index} />
        </>
      )}

      {/* Post Op */}
      {tabState.activeTab !== 'Cancelled (Form Not Sent)' && patient.factors && (
        <>
          <span className='post-op-col'>
            {patient.factors.post_op_care ? (
              <div ref={postOpHoverRef} style={{ position: 'relative', width: '25px' }}>
                {skin === 'velys' ? (
                  <VelysPostOpIcon />
                ) : (
                  <PostOpIcon
                    color={
                      postOpIsHovered
                        ? labeledStyles.row.hoverIconColor
                        : labeledStyles.row.iconColor
                    }
                  />
                )}
                {postOpIsHovered && (
                  <p className='explanation'>
                    Answers indicate a{' '}
                    <span
                      style={{
                        fontWeight: '600',
                        color: labeledStyles.row.hoverIconColor
                      }}
                    >
                      lack of post-op care
                    </span>{' '}
                    in the home
                  </p>
                )}
              </div>
            ) : (
              '--'
            )}
          </span>
          <VerticalLine index={index} />
        </>
      )}

      {/* Status */}
      {tabState.activeTab !== 'Cancelled (Form Not Sent)' && (
        <div className='status-col' ref={statusHoverRef}>
          <div
            className='status-box'
            onClick={(e) => {
              e.stopPropagation()
              if (!e.target.classList.contains('status-tool')) {
                toggleStatusBox(e)
              }
            }}
            onMouseEnter={(e) => {
              e.stopPropagation()
              if (!e.target.classList.contains('status-tool')) {
                toggleStatusBox(e)
              }
            }}
            onMouseLeave={(e) => isStatusToolOpen && toggleStatusBox(e)}
            onBlur={(e) => isStatusToolOpen && toggleStatusBox(e)}
          >
            <div className='intake-icon-div' style={{ width: 25 }}>
              {(patient.status === 0 || patient.status === 1) && (
                <img src='../../images/incomplete-survey.svg' alt='survey icon' />
              )}
              {(patient.status === 2 || patient.status === 3) && (
                <img src='../../images/complete-survey.svg' alt='survey icon' />
              )}
            </div>
            <div
              className={`${isStatusToolOpen ? 'status-arrow-active' : 'status-arrow-inactive'}`}
            ></div>
            {isStatusToolOpen && (
              <StatusTool
                setStatusToolOpen={setStatusToolOpen}
                status={patient.status}
                eventId={patient.calendarId}
                surveyId={patient.id}
                handleViewIntakeClick={handleViewIntakeClick}
                handleRebookClick={handleRebookClick}
                setRebookModalOpen={setRebookModalOpen}
                dispatch={dispatch}
              />
            )}
          </div>
        </div>
      )}
      {tabState.activeTab === 'Cancelled (Form Not Sent)' && (
        <>
          <span className='send-form-col' style={{ rowFontStyles }}>
            <div style={{ position: 'relative' }} onClick={(e) => e.stopPropagation()}>
              <Toggle
                label='Send Form'
                changeFunction={() =>
                  setSendFormChecked(!state.arrayOfPatientsToSendForm.includes(patient.calendarId))
                }
                isChecked={isSendFormChecked}
              />
            </div>
          </span>
        </>
      )}
    </div>
  )
}

export default Row
