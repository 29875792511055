import { useState, useEffect } from 'react';

export function useMedia(query) {
	let [matches, setMatches] = useState(
		window.matchMedia(query).matches
		)
		
	// console.log(query, 'query in useMedia');
	// console.log(matches, 'matches in useMedia');
	
	useEffect(
		() => {
			let media = window.matchMedia(query);
			// console.log(media, 'media in useMedia');
			if(media.matches !== matches) {
				setMatches(media.matches);
			}
			let listener = () => setMatches(media.matches);
			media.addListener(listener)
			return () => media.removeListener(listener);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[query]
	);
	
	return matches;
}