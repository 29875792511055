import React from 'react'
import styled from 'styled-components'
import { labeledStyles } from '../../styles'

const Toggle = ({ label, changeFunction, isChecked }) => {
  function onChange(e) {
    changeFunction()
  }

  return (
    <div>
      <Input type='checkbox' checked={isChecked} onChange={onChange} />
      <Span
        checked={isChecked}
        checkboxColor={isChecked ? labeledStyles.filters.checkboxColor : 'white'}
        border={
          isChecked
            ? `2px solid ${labeledStyles.filters.checkboxColor}`
            : labeledStyles.filters.checkboxBorder
        }
      />
      <Label fontFamily={labeledStyles.filters.fontFamily}>{label}</Label>
    </div>
  )
}

const Input = styled.input`
  position: absolute;
  height: 15px;
  width: 80px;
  opacity: 0;
  z-index: 10;
  &:hover {
    cursor: pointer;
  }
`

const Span = styled.span`
  background-color: ${(props) => props.checkboxColor};
  border: ${(props) => props.border};
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 3px;
  &:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 0px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const Label = styled.span`
  padding-left: 25px;
  font-family: ${(props) => props.fontFamily};
`

export default Toggle
