import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../styles'
import LoadingIndicator from './LoadingIndicator'

const PrioritySelector = (props) => {
  props = {
    ...{
      highColor: colors.green,
      mediumColor: colors.blueDocspera,
      lowColor: colors.blueLow,
      naColor: colors.purpleLightGrey,
      fontFamily: 'Open Sans'
    },
    ...props
  }

  const [isOpen, setOpen] = useState(false)

  const handleArrowClick = () => {
    setOpen(!isOpen)
  }

  useEffect(() => {
    if (props.offset === 0 || props.offset < 0) {
      props.setOffset(0.0999)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.offset])

  const handleOptionClick = (e) => {
    if (e.target.dataset.value === 'high') {
      props.setValue(0.9 + props.offset)
      props.setOffset(parseFloat((props.offset - 0.00005).toFixed(5)))
    }
    if (e.target.dataset.value === 'med') {
      if (props.value >= 0.9) {
        props.setValue(0.899 - props.offset)
        props.setOffset(parseFloat((props.offset - 0.00005).toFixed(5)))
      } else {
        props.setValue(0.1 + props.offset)
        props.setOffset(parseFloat((props.offset - 0.00005).toFixed(5)))
      }
    }
    if (e.target.dataset.value === 'low') {
      props.setValue(0.099 - props.offset)
      props.setOffset(parseFloat((props.offset - 0.00005).toFixed(5)))
    }
    setOpen(!isOpen)
  }

  let bgColor
  let content
  let options
  switch (true) {
    case props.value === null:
      content = 'N / A'
      bgColor = props.naColor
      options = ['high', 'med', 'low']
      break
    case props.value >= 0.9:
      content = 'High'
      bgColor = props.highColor
      options = ['med', 'low']
      break
    case props.value < 0.9 && props.value >= 0.1:
      content = 'Med'
      bgColor = props.mediumColor
      options = ['high', 'low']
      break
    case props.value < 0.1:
      content = 'Low'
      bgColor = props.lowColor
      options = ['high', 'med']
      break
    default:
      content = 'N / A'
      bgColor = props.naColor
      options = ['high', 'med', 'low']
      break
  }

  // Close dropdown if user clicks outside of it
  const node = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.current])

  const handleOutsideClick = (e) => {
    if (node.current) {
      if (node.current.contains(e.target)) {
        return
      }
    }
    setOpen(false)
  }

  return (
    <Div ref={node}>
      <Tag bgColor={bgColor}>
        <span>
          {props.loading ? (
            <LoadingIndicator color={'white'} backgroundColor={bgColor} size='10px' />
          ) : (
            content
          )}
        </span>
      </Tag>
      <Button onClick={handleArrowClick} bgColor={bgColor}>
        <Arrow
          borderTop={isOpen ? '4px solid transparent' : '5px solid white'}
          borderRight={!isOpen && '4px solid transparent'}
          borderBottom={isOpen && '4px solid transparent'}
          borderLeft={isOpen ? '5px solid white' : '4px solid transparent'}
        />
      </Button>
      {isOpen && (
        <Options
          key='priority-options'
          initial='collapsed'
          animate='open'
          exit='collapsed'
          variants={{
            open: { width: 'auto' },
            collapsed: { width: 0 }
          }}
          transition={{
            duration: 0.2,
            staggerChildren: 0.05
          }}
        >
          {options.map((option, idx) => {
            let optionBg
            let optionContent
            switch (option) {
              case 'high':
                optionBg = props.highColor
                optionContent = 'High'
                break
              case 'med':
                optionBg = props.mediumColor
                optionContent = 'Med'
                break
              case 'low':
                optionBg = props.lowColor
                optionContent = 'Low'
                break
              default:
                return null
            }

            return (
              <Option
                key={`${option}-${idx}`}
                bgColor={optionBg}
                onClick={handleOptionClick}
                data-value={option}
              >
                <span
                  // onClick={handleOptionClick}
                  data-value={option}
                  style={{ fontFamily: props.fontFamily }}
                >
                  {optionContent}
                </span>
              </Option>
            )
          })}
        </Options>
      )}
    </Div>
  )
}

export default PrioritySelector

const Div = styled.div`
  display: flex;
  position: relative;
  width: 80px;
`

const Tag = styled.div`
  background-color: ${(props) => props.bgColor};
  width: 50px;
  height: 23px;
  border-radius: 3px 0 0 3px;
  margin: 3px 2px 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    user-select: none;
    display: block;
    text-align: center;
    color: white;
    padding: 0 5px;
    font-weight: 500;
  }
`

const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 18px;
  height: 23px;
  margin: 3px 0;
  border-radius: 0 3px 3px 0;
  border: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const Arrow = styled.div`
  width: 0;
  height: 0;
  border-top: ${(props) => props.borderTop};
  border-right: ${(props) => props.borderRight};
  border-bottom: ${(props) => props.borderBottom};
  border-left: ${(props) => props.borderLeft};
`

const Options = styled.div`
  margin-top: 3px;
  position: absolute;
  left: 73px;
  bottom: 0px;
`

const Option = styled.div`
  background-color: ${(props) => props.bgColor};
  border: 0;
  border-radius: 3px;
  height: 23px;
  margin-bottom: 2px;
  margin-left: 2px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    user-select: none;
    color: white;
    font-weight: 500;
    padding: 0;
  }
`
