export default function buildTimeBar(value) {
  const startTime = value.clone().startOf('day').add(7, 'hours')
  const endTime = value.clone().startOf('day').add(18, 'hours')

  const hour = startTime.clone().subtract(15, 'minutes')
  const timebar = []
  // timebar algo
  while(hour.isBefore(endTime, 'hour')){
    timebar.push(
      Array(48)
      .fill(0)
      .map(() => hour.add(15, 'minutes').clone())
    )
  }
  return timebar
}