import React, { useContext, useState, useEffect } from "react";
import TimeBar from "./TimeBar/TimeBar";
import HeatCalendar from "../HeatCalendar/HeatCalendar";
import { labeledStyles } from "../../styles";
import {
  Context,
  getAvailability,
  setNotification,
} from "../../context/Context";
import "./RebookModal.scss";
import moment from "moment";
import { useHorizontalScroll } from "../../hooks/useHorizontalScroll";

const RebookModal = ({ toggleModal }) => {
  const { state, dispatch } = useContext(Context);
  const [dateSelected, setDateSelected] = useState();
  const [rebooked, setRebooked] = useState(false);
  const [rebookedTimes, setRebookedTimes] = useState();

  const scrollRef = useHorizontalScroll();

  const sortedDates = state?.rebookInfo?.availability?.response
    ?.filter(Boolean)
    .sort((a, b) => new Date(a) - new Date(b));
  useEffect(() => {
    // Select patient's first available date
    if (state?.rebookInfo?.availability?.response) {
      for (let date of state?.rebookInfo?.availability?.response) {
        if (date) {
          setDateSelected(date);
          break;
        }
      }
    } else {
      setDateSelected(moment().format("YYYYMMDD"));
    }
  }, [state.rebookInfo]);

  useEffect(() => {
    if (dateSelected !== undefined) {
      // Get Availability for Time Bar
      getAvailability(state.rebookInfo.physician, dateSelected, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected]);

  return (
    <div className="modal-bg" onClick={() => dispatch(toggleModal(false))}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        {/* Header Div */}
        <div
          className="header"
          style={{
            height: 60,
            backgroundColor: labeledStyles.modal.headerBackground,
            padding: 15,
            paddingLeft: 20,
            borderRadius: "20px 20px 0 0",
          }}
        >
          <div style={{ display: "flex", marginTop: 10 }}>
            <div>
              <h4
                style={{
                  fontFamily: labeledStyles.rebookModal.header.fontFamily,
                  fontWeight: labeledStyles.rebookModal.header.fontWeight,
                }}
              >
                Rebook
              </h4>
              <span style={{ fontFamily: labeledStyles.rebookModal.value }}>
                {state.rebookInfo.patientName}
              </span>
            </div>
            {state.rebookInfo.caseName && (
              <div
                style={{
                  marginLeft: 40,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    fontFamily: labeledStyles.tableHeader.fontFamily,
                    fontSize: labeledStyles.tableHeader.fontSize,
                    fontWeight: labeledStyles.tableHeader.fontWeight,
                    color: labeledStyles.tableHeader.color,
                    textTransform: labeledStyles.tableHeader.textTransform,
                    marginTop: 5,
                    marginBottom: 7,
                  }}
                >
                  Procedure
                </span>
                <span style={{ fontFamily: labeledStyles.rebookModal.value }}>
                  {state.rebookInfo.caseName}
                </span>
              </div>
            )}
            {state.rebookInfo.duration && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 30,
                }}
              >
                <span
                  style={{
                    fontFamily: labeledStyles.tableHeader.fontFamily,
                    fontSize: labeledStyles.tableHeader.fontSize,
                    fontWeight: labeledStyles.tableHeader.fontWeight,
                    color: labeledStyles.tableHeader.color,
                    textTransform: labeledStyles.tableHeader.textTransform,
                    marginTop: 5,
                    marginBottom: 7,
                  }}
                >
                  Duration
                </span>
                <span style={{ fontFamily: labeledStyles.rebookModal.value }}>
                  {state.rebookInfo.duration} Min
                </span>
              </div>
            )}
          </div>
          <button
            id="docbox-close-btn"
            className="close"
            onClick={() => dispatch(toggleModal(false))}
          >
            <div>
              <img
                className="exit"
                onClick={() => {
                  dispatch(toggleModal(false));
                }}
                src="../../../images/exit.svg"
                alt="exit modal"
              />
            </div>
          </button>
        </div>

        {/* Content Div */}
        <div className="content-div">
          {!rebooked ? (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/* Date Tabs */}
                <div style={{ width: "80%" }}>
                  <span
                    style={{
                      fontFamily: labeledStyles.tableHeader.fontFamily,
                      fontSize: labeledStyles.tableHeader.fontSize,
                      fontWeight: labeledStyles.tableHeader.fontWeight,
                      color: labeledStyles.tableHeader.color,
                      textTransform: labeledStyles.tableHeader.textTransform,
                    }}
                  >
                    Patient's Available Dates
                    {sortedDates?.length !==
                      undefined &&
                      `(${sortedDates?.length})`}
                  </span>
                  {state?.rebookInfo?.availability?.response?.length && (
                    <div ref={scrollRef} className="date-tabs-div">
                      {state.rebookInfo.availability.response &&
                        sortedDates.map((date, idx) => {
                          if (date) {
                            return (
                              <div
                                key={idx}
                                className="date-div"
                                style={{ paddingRight: 20, cursor: "pointer" }}
                                onClick={() => setDateSelected(date)}
                              >
                                <span
                                  style={{
                                    fontSize: 14,
                                    color: moment(dateSelected).isSame(
                                      date,
                                      "d"
                                    )
                                      ? "black"
                                      : "grey",
                                    marginTop: 5,
                                  }}
                                >
                                  {moment(date, "YYYY-MM-DD hh:mm:ss").format(
                                    "MMM DD, YYYY"
                                  )}
                                </span>
                                <div
                                  style={{
                                    backgroundColor: moment(
                                      dateSelected
                                    ).isSame(date, "d")
                                      ? labeledStyles.tabs.underlineColor
                                      : "transparent",
                                    height: 3,
                                    width: "100%",
                                    marginTop: 5,
                                    borderRadius: 10,
                                  }}
                                ></div>
                              </div>
                            );
                          } else {
                            return null
                          }
                        })}
                    </div>
                  )}
                </div>
                <div
                  className={"caledar-picker"}
                  style={{ width: 200, marginRight: 10 }}
                >
                  <span
                    style={{
                      fontFamily: labeledStyles.tableHeader.fontFamily,
                      fontSize: labeledStyles.tableHeader.fontSize,
                      fontWeight: labeledStyles.tableHeader.fontWeight,
                      color: labeledStyles.tableHeader.color,
                      textTransform: labeledStyles.tableHeader.textTransform,
                      display: "block",
                      padding: "7px 0px 10px 0px",
                    }}
                  >
                    Date Selector
                  </span>
                  <HeatCalendar
                    value={dateSelected}
                    handler={(event) =>
                      setDateSelected(event.target.dataset.date)
                    }
                    style={{
                      width: "98%",
                    }}
                  />
                </div>
              </div>
              <div style={{ paddingTop: 40 }}>
                <span
                  style={{
                    fontFamily: labeledStyles.tableHeader.fontFamily,
                    fontSize: labeledStyles.tableHeader.fontSize,
                    fontWeight: labeledStyles.tableHeader.fontWeight,
                    color: labeledStyles.tableHeader.color,
                    textTransform: labeledStyles.tableHeader.textTransform,
                    display: "block",
                    paddingBottom: 15,
                  }}
                >
                  Physician's Available Hours
                </span>
                <TimeBar
                  selectedDate={dateSelected}
                  availability={state.availability}
                  patients={state.patients}
                  eventDuration={state.rebookInfo.duration}
                  eventId={state.rebookInfo.eventId}
                  config={state.config}
                  setRebooked={setRebooked}
                  setRebookedTimes={setRebookedTimes}
                  setNotification={setNotification}
                  dispatch={dispatch}
                />
              </div>
            </>
          ) : (
            <div style={{ margin: "auto 0" }}>
              {rebookedTimes && (
                <>
                  <p
                    style={{
                      marginBottom: 5,
                      marginTop: 30,
                      textAlign: "center",
                    }}
                  >
                    This case has successfully been rebooked:
                  </p>
                  <h4 style={{ marginBottom: 5, textAlign: "center" }}>
                    {moment(rebookedTimes.start, "YYYYMMDDhhmmss").format(
                      "MMMM DD, YYYY"
                    )}{" "}
                    (
                    {moment(rebookedTimes.start, "YYYYMMDDhhmmss").format(
                      "h:mma"
                    )}{" "}
                    -{" "}
                    {moment(rebookedTimes.end, "YYYYMMDDhhmmss").format(
                      "h:mma"
                    )}
                    )
                  </h4>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RebookModal;
