import React from 'react'

const CovidIcon = () => {
	return (
		<svg x="0px" y="0px"
			viewBox="0 0 32.3 28.6" >
				<g transform="translate(-675.939 -479.608)">
					<path style={{fill:'none', stroke:'#1478B4', strokeWidth:1.7, strokeLinecap:'round'}} d="M705,507.3h-25.8c-1.3,0-2.4-1.1-2.4-2.4c0-0.4,0.1-0.8,0.3-1.2l12.6-21.9c0.8-1.3,2.4-1.8,3.8-1
						c0.4,0.2,0.8,0.6,1,1l12.6,21.9c0.7,1.2,0.3,2.6-0.9,3.3C705.8,507.2,705.4,507.3,705,507.3z"/>
					<line style={{fill:'none', stroke:'#1478B4', strokeWidth:1.7, strokeLinecap:'round'}}x1="692.1" y1="489.2" x2="692.1" y2="498.1"/>
					<circle style={{fill:'#1478B4'}} cx="692.1" cy="502" r="1"/>
				</g>
		</svg>
	)
}

export default CovidIcon