import React from 'react'

const PostOpIcon = (props) => {
  return (
    <div style={{ width: 25 }}>
      <svg x='0px' y='0px' viewBox='0 0 35 29'>
        <g transform='translate(-1716 -637)'>
          <path
            id='Rectangle_280'
            style={{ fill: props.color }}
            d='M1722,650h23c0.6,0,1,0.4,1,1v14c0,0.6-0.4,1-1,1h-23c-0.6,0-1-0.4-1-1v-14
          C1721,650.4,1721.4,650,1722,650z'
          />
          <path
            id='Polygon_104'
            style={{ fill: props.color }}
            d='M1732.8,637.6c0.4-0.3,1-0.3,1.4,0l14.9,13.6c0.4,0.4,0.4,1,0.1,1.4
          c-0.2,0.2-0.5,0.3-0.7,0.3h-29.8c-0.6,0-1-0.4-1-1c0-0.3,0.1-0.5,0.3-0.7L1732.8,637.6z'
          />
          <path
            id='Rectangle_278'
            style={{ fill: '#FFF' }}
            d='M1732.2,655.5v-10.1c0-0.7,0.6-1.3,1.3-1.3l0,0c0.7,0,1.3,0.6,1.3,1.3v10.1
          c0,0.7-0.6,1.3-1.3,1.3l0,0C1732.8,656.8,1732.2,656.2,1732.2,655.5z'
          />
          <path
            id='Rectangle_279'
            style={{ fill: '#FFF' }}
            d='M1732.2,660.6L1732.2,660.6c0-0.7,0.6-1.3,1.3-1.3l0,0c0.7,0,1.3,0.6,1.3,1.3l0,0
          c0,0.7-0.6,1.3-1.3,1.3l0,0C1732.8,661.8,1732.2,661.3,1732.2,660.6z'
          />
        </g>
      </svg>
    </div>
  )
}

export default PostOpIcon
