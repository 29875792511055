import React from 'react'

const ComorbiditiesIcon = (props) => {
  return (
    <div style={{ width: '33px' }}>
      <svg x='0px' y='0px' viewBox='0 0 35.6 35.6'>
        <g transform='translate(-983 -518)'>
          <g transform='translate(582 226)'>
            <g transform='matrix(1, 0, 0, 1, 401, 292)'>
              <path
                style={{ fill: 'white' }}
                d='M5.3,2.3h25c1.7,0,3,1.3,3,3v25c0,1.7-1.3,3-3,3h-25c-1.7,0-3-1.3-3-3v-25
                C2.3,3.6,3.6,2.3,5.3,2.3z'
              />
            </g>
          </g>
          <path
            style={{ fill: props.color }}
            d='M996.8,527.3h14c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5h-14
            c-0.8,0-1.5-0.7-1.5-1.5l0,0C995.3,528,996,527.3,996.8,527.3z'
          />
          <path
            style={{ fill: props.color }}
            d='M991.8,527.3L991.8,527.3c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5l0,0
            c-0.8,0-1.5-0.7-1.5-1.5l0,0C990.3,528,991,527.3,991.8,527.3z'
          />
          <path
            style={{ fill: props.color }}
            d='M991.8,532.3L991.8,532.3c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5l0,0
            c-0.8,0-1.5-0.7-1.5-1.5l0,0C990.3,533,991,532.3,991.8,532.3z'
          />
          <path
            style={{ fill: props.color }}
            d='M991.8,537.3L991.8,537.3c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5l0,0
            c-0.8,0-1.5-0.7-1.5-1.5l0,0C990.3,538,991,537.3,991.8,537.3z'
          />
          <path
            style={{ fill: props.color }}
            d='M991.8,542.3L991.8,542.3c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5l0,0
            c-0.8,0-1.5-0.7-1.5-1.5l0,0C990.3,543,991,542.3,991.8,542.3z'
          />
          <path
            style={{ fill: props.color }}
            d='M996.8,532.3h14c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5h-14
            c-0.8,0-1.5-0.7-1.5-1.5l0,0C995.3,533,996,532.3,996.8,532.3z'
          />
          <path
            style={{ fill: props.color }}
            d='M996.8,537.3h14c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5h-14
            c-0.8,0-1.5-0.7-1.5-1.5l0,0C995.3,538,996,537.3,996.8,537.3z'
          />
          <path
            style={{ fill: props.color }}
            d='M996.8,542.3h14c0.8,0,1.5,0.7,1.5,1.5l0,0c0,0.8-0.7,1.5-1.5,1.5h-14
            c-0.8,0-1.5-0.7-1.5-1.5l0,0C995.3,543,996,542.3,996.8,542.3z'
          />
        </g>
      </svg>
    </div>
  )
}

export default ComorbiditiesIcon
