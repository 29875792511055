import React, { useEffect, useContext, useRef } from 'react'
import { Context } from '../../context/Context'
import RenderButtons from './RenderButtons'
import { colors } from '../../styles'
import './StatusTool.scss'

const StatusTool = ({
  setStatusToolOpen,
  status,
  eventId,
  surveyId,
  handleViewIntakeClick,
  handleRebookClick
}) => {
  const { dispatch } = useContext(Context)
  const ref = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = (e) => {
    if (ref.current.contains(e.target)) {
    } else {
      setStatusToolOpen(false)
    }
  }

  const findText = (status) => {
    switch (status) {
      case 0:
      case 1:
        return 'Form Sent & Not Completed'
      case 2:
        return 'Form Complete & Rebookable'
      case 3:
        return 'Rebooked'
      default:
        return
    }
  }

  return (
    <div ref={ref} className='status-tool'>
      <div
        style={{
          display: 'flex',
          padding: '15px 10px',
          borderBottom: `2px solid ${colors.superLightGrey}`
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
          {(status === 0 || status === 1) && (
            <img
              src="../../images/incomplete-survey.svg"
              className="survey-icon"
              alt="survey incomplete icon"
            />
          )}
          {(status === 2 || status === 3) && (
            <img
              src="../../images/complete-survey.svg"
              className="survey-icon"
              alt="survey complete icon"
            />
          )}
          <span style={{ fontSize: 14 }}>{findText(status)}</span>
        </div>
      </div>
      <hr/>
      <RenderButtons
        status={status}
        handleViewIntakeClick={handleViewIntakeClick}
        handleRebookClick={handleRebookClick}
        eventId={eventId}
        surveyId={surveyId}
        dispatch={dispatch}
      />
    </div>
  )
}

export default StatusTool
