import React from 'react'

const CovidIcon = (props) => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 31 29">
      <g transform="translate(-1764 -251)">
        <path id="Polygon_53" style={{fill: props.color }} d="M1777.7,254.3c0.5-1,1.7-1.3,2.7-0.8c0.3,0.2,0.6,0.5,0.8,0.8l12.2,22.8
          c0.5,1,0.2,2.2-0.8,2.7c-0.3,0.2-0.6,0.2-0.9,0.2h-24.3c-1.1,0-2-0.9-2-2c0-0.3,0.1-0.7,0.2-0.9L1777.7,254.3z"/>
        <path id="Rectangle_21" style={{fill: '#FFFFFF'}} d="M1778.2,270.5v-10.1c0-0.7,0.6-1.3,1.3-1.3l0,0c0.7,0,1.3,0.6,1.3,1.3v10.1
          c0,0.7-0.6,1.3-1.3,1.3l0,0C1778.8,271.8,1778.2,271.2,1778.2,270.5z"/>
        <path id="Rectangle_25" style={{fill: '#FFFFFF'}} d="M1778.2,275.6L1778.2,275.6c0-0.7,0.6-1.3,1.3-1.3l0,0c0.7,0,1.3,0.6,1.3,1.3l0,0
          c0,0.7-0.6,1.3-1.3,1.3l0,0C1778.8,276.8,1778.2,276.3,1778.2,275.6z"/>
      </g>
    </svg>
  )
}

export default CovidIcon
