import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import './fonts/Nunito/Nunito-Bold.ttf'
import './fonts/Rubik/Rubik-Bold.ttf'
import './fonts/Rubik/Rubik-SemiBold.ttf'
import './fonts/Rubik/Rubik-Medium.ttf'
import './fonts/Rubik/Rubik-Regular.ttf'
import './fonts/Arimo/Arimo-Bold.ttf'
import './fonts/Arimo/Arimo-Regular.ttf'
import './fonts/Arimo/Arimo-SemiBold.ttf'
import './fonts/Arimo/Arimo-Medium.ttf'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
