import React from 'react'
import { useHover } from '../hooks/useHover'
import { labeledStyles, colors } from '../styles'

const HoverDescription = (props) => {
	props = {...{
		borderRadius: labeledStyles.hoverDescription.borderRadius,
		backgroundColor: labeledStyles.hoverDescription.backgroundColor,
		hoverColor: labeledStyles.hoverDescription.hoverColor
	}, ...props
}

	let [hoverRef, isHovered] = useHover()
	return ( 
		<div ref={hoverRef} 
			style={{
				backgroundColor: isHovered ? props.hoverColor : props.backgroundColor, 
				borderRadius: props.borderRadius, 
				width: 13, 
				height: 13, 
				display: 'flex', 
				alignItems: 'center', 
				justifyContent: 'center', 
				marginLeft: -2,
				marginRight: 2, 
				cursor: 'pointer',
			}}
		>
			{isHovered && (
				<div style={{
					position: 'absolute', 
					backgroundColor: 'white', 
					border: `2px solid ${props.hoverColor}`, 
					width: 200, 
					top: 37, 
					zIndex: 99, 
					padding: 10, 
					borderRadius: 3,
					boxShadow: isHovered && `1px 1px 10px ${colors.lightGrey}`
				}}>
					<p style={{fontSize: 12, color: 'var(--blueDark)'}}>{props.description}</p>
				</div>
			)}
			<p style={{color: 'white', fontSize: 12, fontWeight: 500}}>?</p>
		</div>
	);
}
 
export default HoverDescription;

