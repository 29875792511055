let labeledStyles
let colors
let skin

if (document.location) {
  let params = new URLSearchParams(document.location.search.substring(1))
  skin = params.get('skin')
}

switch (skin) {
  case 'velys':
    colors = {
      dataGreen: '#00B463',
      lightGreen: '#d3e8c0',
      dataPurple: '#675DC5',
      darkBlue: '#1478B4',
      velysBlue: '#00B4E1',
      lightBlue: '#52C8E7',
      darkGrey: '#4A4A4A',
      mediumGrey: '#707070',
      lightGrey: '#E4E4E4',
      superLightGrey: '#F7F7F7',
      backgroundGrey: '#F7F8FB',
      iconGrey: '#9B9B9B',
      yellow: '#FFB41D',
      red: '#FB021C'
    }
    break

  default:
    colors = {
      blueDark: '#022546',
      blueDocspera: '#0096FA', // Medium
      blueLow: '#80D6FF', // Low
      blueDocsperaLight: '#40B0FA',
      bluePale: '#E5F3FA',
      blueFaint: '#EBFFFF',
      blueLight: '#B6E3F8',
      green: '#01BE17', // High
      greenFaint: '#CCF0E0',
      red: '#FB021C',
      redDark: '#D6041A',
      yellowOrange: '#FFB41D',
      yellow: '#FFB41D',
      lavender: '#9399E2',
      purpleGrey: '#839EB7',
      purpleLightGrey: '#A8C6E2', // N/A
      mediumGrey: '#707070',
      superLightGrey: '#F7F7F7'
    }
    break
}

switch (skin) {
  case 'velys':
    labeledStyles = {
      dashboard: {
        h1Font: 'Nunito',
        backgroundColor: colors.backgroundGrey,
        maxWidth: '1776px'
      },

      policyPicker: {
        primary: colors.velysBlue,
        secondary: colors.darkBlue,
        tertiary: colors.iconGrey,
        fontFamily: 'Rubik',
        labelFont: 'Rubik',
        labelWeight: 300,
        labelFontSize: '11px',
        background: 'white',
        menuHeaderFontColor: 'black',
        menuHeaderBGColor: 'white',
        menuHeaderBorder: `1px solid ${colors.lightGrey}`,
        menuHeaderBorderRadius: '10px',
        boxShadow: false,
        dropdownLabelColor: colors.mediumGrey,
        dropdownShadow: `1px 1px 10px ${colors.lightGrey}`,
        hamburger: false,

        sectionHdrBG: 'white',
        sectionHdrColor: colors.iconGrey,
        sectionHdrCaps: true,
        sectionHdrFontWeight: 300,
        sectionHdrBorderBottom: `2px solid ${colors.superLightGrey}`,

        optionSelectedBorder: 'white',
        markerWidth: 0,
        inputBorderColor: colors.iconGrey,
        inputBorderWidth: '1px',
        inputTop: '5px',
        inputSize: '13px',
        optionPadding: 0,
        optionTitleSelectedColor: 'black',
        optionTitleFontSize: '16px',
        labelPadding: 0
      },

      filters: {
        fontFamily: 'Rubik',
        badgeBackground: 'white',
        border: `1px solid ${colors.lightGrey}`,
        badgeFontColor: 'black',
        checkboxColor: colors.lightBlue,
        checkboxBorder: `2px solid ${colors.mediumGrey}`,
        borderRadius: '10px'
      },

      buttons: {
        background: colors.lightBlue,
        hoverBackground: 'white',
        borderColor: colors.lightBlue,
        hoverBorderColor: colors.darkGrey,
        color: 'black',
        hoverColor: 'black',
        fontFamily: 'Rubik'
      },

      table: {
        background: colors.backgroundGrey,
        hrBorder: '0 solid transparent',
        paddingLeft: 10,
        borderRadius: 10,
        boxShadow: `1px 1px 10px ${colors.lightGrey}`
      },

      tableHeader: {
        background: 'transparent',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 300,
        color: colors.mediumGrey,
        textTransform: 'uppercase',
        boxColor: 'white',
        caretActiveColor: 'black',
        caretInactiveColor: colors.iconGrey
      },

      statusActionButtons: {
        background: 'transparent',
        hoverBackground: 'transparent',
        borderColor: 'transparent',
        hoverBorderColor: 'transparent',
        color: colors.velysBlue,
        hoverColor: 'black',
        fontFamily: 'Rubik'
      },

      hoverDescription: {
        borderRadius: '10px',
        backgroundColor: colors.iconGrey,
        hoverColor: colors.velysBlue
      },

      row: {
        spanFont: 'Rubik',
        iconColor: colors.darkBlue,
        hoverIconColor: colors.darkBlue,
        alternatingRowColor: 'white',
        style: 'grid',
        fontWeight: 300,
        fontSize: 12
      },

      prioritySelector: {
        high: colors.dataGreen,
        medium: colors.dataPurple,
        low: colors.velysBlue,
        na: colors.mediumGrey,
        fontFamily: 'Rubik'
      },

      modal: {
        extBackground: 'rgba(112, 112, 112, .7)', //#707070
        headerBackground: '#EDF7FB',
        headerBorderBottom: 0,
        borderRadius: 10,
        h3: {
          // "Survey Results"
          fontFamily: 'Nunito',
          fontSize: '22px',
          color: colors.darkGrey
        },
        p: {
          // Name & Answers
          fontFamily: 'Nunito',
          fontWeight: 400,
          color: 'black'
        },
        h4: {
          // Question Prompts
          fontFamily: 'Rubik',
          color: colors.darkGrey
        }
      },
      tabs: {
        underlineColor: colors.lightBlue
      },

      rebookModal: {
        //Rebook
        header: {
          fontFamily: 'Nunito',
          fontWeight: 600
        },
        value: 'Open Sans'
      },

      timebar: {
        bookableColor: colors.lightBlue,
        availableColor: colors.lightGreen
      }
    }

    break

  default:
    // default = DocSpera Styles
    labeledStyles = {
      dashboard: {
        backgroundColor: 'transparent',
        maxWidth: '1776px'
      },

      policyPicker: {
        primary: colors.blueDocspera,
        secondary: colors.blueDocsperaLight,
        tertiary: colors.purpleGrey,
        fontFamily: 'Open Sans',
        labelFont: 'Arimo',
        labelWeight: 600,
        labelFontSize: '11px',
        background: colors.bluePale,
        menuHeaderFontColor: 'white',
        menuHeaderBGColor: colors.blueDocspera,
        menuHeaderBorderRadius: '3px',
        menuHeaderBorder: 0,
        boxShadow: true,
        dropdownLabelColor: 'black',
        hamburger: true,

        sectionHdrBG: colors.blueDocsperaLight,
        sectionHdrColor: 'white',
        sectionHdrCaps: false,
        sectionHdrFontWeight: 500,
        sectionHdrBorderBottom: 0,

        optionSelectedBorder: colors.blueDocspera,
        markerWidth: '8px',
        inputBorderColor: colors.purpleLightGrey,
        inputBorderWidth: '2px',
        inputTop: '19px',
        inputSize: '15px',
        optionPadding: '10px 0 10px 0',
        optionTitleSelectedColor: colors.blueDocspera,
        optionTitleFontSize: '1.17em',
        labelPadding: '7px 20px 5px 5px'
      },

      filters: {
        fontFamily: 'Open Sans',
        badgeBackground: colors.blueDocspera,
        border: `1px solid ${colors.blueDocspera}`,
        badgeFontColor: 'white',
        checkboxColor: colors.blueDocspera,
        checkboxBorder: `2px solid ${colors.blueDocsperaLight}`,
        borderRadius: '3px'
      },

      buttons: {
        background: 'white',
        hoverBackground: colors.blueDocspera,
        borderColor: colors.blueDocspera,
        hoverBorderColor: colors.blueDocspera,
        color: colors.blueDocspera,
        hoverColor: 'white',
        fontFamily: 'Open Sans'
      },

      table: {
        background: 'white',
        hrBorder: `.5px solid ${colors.blueDocsperaLight}`,
        paddingLeft: 0,
        borderRadius: '3px',
        boxShadow: `1px 1px 10px lightgrey`
      },

      tableHeader: {
        background: 'white',
        fontFamily: 'Arimo',
        fontSize: '12px',
        fontWeight: 600,
        color: 'black', // medium grey
        textTransform: 'capitalize',
        boxColor: '#DAF0FB', // some light blue
        caretActiveColor: 'black',
        caretInactiveColor: '#DAF0FB' // some light blue
      },

      statusActionButtons: {
        background: 'white',
        hoverBackground: colors.blueDocspera,
        borderColor: colors.blueDocspera,
        hoverBorderColor: colors.blueDocspera,
        color: colors.blueDocspera,
        hoverColor: 'white',
        fontFamily: 'Open Sans'
      },

      hoverDescription: {
        borderRadius: 10,
        backgroundColor: colors.purpleLightGrey,
        hoverColor: colors.blueDocspera
      },

      row: {
        spanFont: 'Open Sans',
        iconColor: colors.purpleLightGrey,
        hoverIconColor: colors.blueDark,
        alternatingRowColor: colors.blueFaint,
        style: 'striped',
        fontWeight: 400,
        fontSize: '12px'
      },

      prioritySelector: {
        high: colors.green,
        medium: colors.blueDocspera,
        low: colors.blueLow,
        na: colors.purpleLightGrey,
        fontFamily: 'Open Sans'
      },

      modal: {
        extBackground: 'rgba(112, 112, 112, .7)', //#707070
        headerBackground: 'white',
        headerBorderBottom: '1px solid lightgrey',
        borderRadius: 10,
        h3: {
          // "Survey Results"
          fontFamily: 'Arimo',
          fontSize: '22px',
          color: colors.blueDocspera
        },
        p: {
          // Name & Answers
          fontFamily: 'Open Sans',
          fontWeight: 400,
          color: colors.blueDocspera
        },
        h4: {
          // Question Prompts
          fontFamily: 'Open Sans',
          color: colors.blueDocspera
        }
      },
      tabs: {
        underlineColor: colors.blueDocspera
      },
      rebookModal: {
        //Rebook
        header: {
          fontFamily: 'Open Sans',
          fontWeight: 600
        },
        value: 'Open Sans'
      },
      timebar: {
        bookableColor: colors.blueDocsperaLight,
        availableColor: colors.blueFaint
      }
    }
}

export { labeledStyles, colors, skin }
