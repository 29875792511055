import React, { useState, useEffect, useContext } from 'react'
import buildTimeBar from './buildTimeBar'
import { rebookApi } from '../../../api/api'
import moment from 'moment'
import './TimeBar.scss'
import { labeledStyles } from '../../../styles'
import { setUpdatePatients } from '../../../context/Context'
import { Context as TabContext } from '../../../context/TabContext'

const TimeBar = ({
  selectedDate,
  availability,
  eventId,
  eventDuration,
  config,
  patients,
  setRebooked,
  setRebookedTimes,
  setNotification,
  dispatch
}) => {
  const [timebar, setTimebar] = useState([])
  const [currentMoment, setCurrentMoment] = useState(moment())
  const [availableDurationIdxs, setAvailableDurationIdxs] = useState()

  const { state: tabState } = useContext(TabContext)

  useEffect(() => {
    selectedDate && setCurrentMoment(moment(selectedDate))
  }, [selectedDate])

  useEffect(() => {
    setTimebar(buildTimeBar(currentMoment))
  }, [currentMoment])

  const isAvailable = (quarter) => {
    if (quarter) {
      let idx = quarter.format('HH') * 4 + quarter.format('mm') / 15
      if (availability && availability[idx] === '1') {
        return true
      } else {
        return false
      }
    }
  }

  const checkTimeToBook = (quarter, idx) => {
    let duration = eventDuration
    // let duration = 60
    let quarters = duration / 15
    let bookable
    let idxArray = []

    if (isAvailable(quarter)) {
      for (let i = idx; i < idx + quarters; i++) {
        //if succeeding quarters are available...
        if (timebar) {
          if (!isAvailable(timebar[0][i])) {
            bookable = false
            idxArray = []
            return
          }
          bookable = true
          idxArray.push(i)
        }
      }
    }
    setAvailableDurationIdxs(idxArray)
    return bookable
  }

  const bookSlot = (quarter) => {
    let startTime = moment(quarter).format('YYYYMMDDHHmmss a')
    let endTime = moment(quarter).add(eventDuration, 'minutes').format('YYYYMMDDHHmmss a')
    let newTime = {
      start: parseInt(startTime),
      end: parseInt(endTime)
    }

    const url = `/api/v1/intelligent-scheduling/events/${eventId}`
    rebookApi(url, newTime).then((response) => {
      console.log('rebookApi response from TimeBar', response)
      if (response.status === 200) {
        if(tabState.activeTab === 'Rebooking') {
          const updatedPatients = patients.filter(patient => patient.calendarId !== eventId)
          setUpdatePatients(dispatch, updatedPatients)
        }
        dispatch(setNotification('Rebook Successful'))
        setRebooked(true)
        setRebookedTimes(newTime)
      } else {
        dispatch(setNotification('Rebook failed. Please contact Customer Support.'))
      }
    })
  }

  const findBackgroundColor = (idx, quarter) => {
    let color = 'white'

    // if available
    if (isAvailable(quarter)) {
      color = labeledStyles.timebar.availableColor
    } else {
      color = 'lightgrey'
    }
    // on hover, if bookable:
    if (availableDurationIdxs && availableDurationIdxs?.indexOf(idx) !== -1) {
      color = labeledStyles.timebar.bookableColor
    }
    return color
  }

  return (
    <div id='timebar'>
      {selectedDate &&
        timebar.map((day) =>
          day.map((quarter, idx) => {
            return (
              <div
                key={idx}
                className={`
                  quarter-block
                  ${idx === 0 && 'start-block'}
                  ${idx === day.length - 1 && 'end-block'}
                  ${quarter.format('mm') === '00' && idx !== 0 && 'start-of-hour'}
                  ${
                    availableDurationIdxs &&
                    availableDurationIdxs?.indexOf(idx) !== -1 &&
                    'bookable'
                  }
                `}
                style={{
                  backgroundColor: findBackgroundColor(idx, quarter)
                }}
                onMouseOver={() => {
                  checkTimeToBook(quarter, idx)
                }}
                onMouseLeave={() => setAvailableDurationIdxs([])}
                onClick={() => bookSlot(quarter)}
              >
                {/* Times on the Top '6 am' */}
                {quarter.format('mm') === '00' && (
                  <span key={`${idx}-hour`} className={`time-on-top`}>
                    {quarter.format('h a')}
                  </span>
                )}

                {/* Bookable Slots */}
                {availableDurationIdxs && availableDurationIdxs[0] === idx && (
                  <div
                    id='tooltip'
                    style={{ border: `1px solid ${labeledStyles.timebar.bookableColor}` }}
                  >
                    <p style={{ userSelect: 'none' }}>Click to book in this time slot</p>
                  </div>
                )}
              </div>
            )
          })
        )}
    </div>
  )
}

export default TimeBar
