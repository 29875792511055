export const configData = {
  BASE_URL: 'docspera.localhost',
  ABS_BASE_URL: 'https://docspera.localhost',
  REACT_ABS_BASE_URL: 'https://docspera.localhost',
  ENV: 'development',
  IS_URL: 'https://intelligent-scheduler.docspera.localhost',
  SESSION_IDLE_MINUTES: 2,
  SESSION_LOGOUT_MINUTES: 13,
  VELYS_LOGIN_URL: 'https://velysdigitalsurgery-portal.web-sit-aks.thesurgicalnet.com/#/login'
}
