import React, { useContext, useEffect } from 'react'
import {
  Context,
  getConfig,
  firstLoad,
  setRebookModalOpen,
  setConfirmationModalAction,
  clearArrayOfPatientsToSendForm
} from '../context/Context'
import { Context as TabContext } from '../context/TabContext'
import HorizontalPanel from '../components/HorizontalPanel/HorizontalPanel'
import Table from '../components/Table/Table'
import SurveyModal from '../components/SurveyModal/SurveyModal'
import RebookModal from '../components/RebookModal/RebookModal'
import ConfirmationModal from '../components/ConfimationModal/ConfirmationModal'
import SmallScreenSplash from '../components/SmallScreenSplash/SmallScreenSplash'
import Notification from '../components/Notification/Notification'
import Tabs from '../components/Tabs/Tabs'
import { LoadingSplash, Button } from 'docspera-components'
import { labeledStyles } from '../styles'
import './Dashboard.scss'

const Dashboard = () => {
  const { state, dispatch } = useContext(Context)
  const { state: tabState } = useContext(TabContext)

  useEffect(() => {
    getConfig(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!state.isLoaded && !state.isLoading) firstLoad(state, dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isLoaded, dispatch])

  useEffect(() => {
    let body = document.getElementsByTagName('body')[0]
    if (state.isSurveyOpen || state.isRebookModalOpen || state.isConfirmationOpen) {
      body.style = 'overflow: hidden'
    } else {
      body.style = 'overflow: visible'
    }
  }, [state.isSurveyOpen, state.isRebookModalOpen, state.isConfirmationOpen])

  if (!state.isLoaded) {
    return (
      <div className='loading-view'>
        <LoadingSplash />
      </div>
    )
  } else {
    return (
      <div
        id='dashboard'
        style={{
          maxWidth: labeledStyles.dashboard.maxWidth,
          position: 'relative',
          margin: '0 auto'
        }}
      >
        <SmallScreenSplash />
          <div style={{ width: '100%' }}>
            <HorizontalPanel />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 125,
                position: 'relative'
              }}
            >
              <Tabs
                tabs={[
                  { name: 'Rebooking' },
                  { name: 'Cancelled (Form Not Sent)' },
                  { name: 'Already Rebooked' }
                ]}
              />
              {tabState.activeTab === 'Cancelled (Form Not Sent)' &&
                state.arrayOfPatientsToSendForm.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      position: 'absolute',
                      right: 0
                    }}
                  >
                    <Button
                      id='bulk-send-btn'
                      content={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src='../../images/send_icon.svg'
                            alt='send icon'
                            style={{ width: 16 }}
                          />
                          <span
                            style={{ fontFamily: labeledStyles.buttons.fontFamily, marginLeft: 10 }}
                          >
                            Send Form ({state.arrayOfPatientsToSendForm.length})
                          </span>
                        </div>
                      }
                      size='medium'
                      background={labeledStyles.buttons.background}
                      hoverStyles={{
                        background: labeledStyles.buttons.hoverBackground,
                        borderColor: labeledStyles.buttons.hoverBorderColor,
                        color: labeledStyles.buttons.hoverColor
                      }}
                      borderColor={labeledStyles.buttons.borderColor}
                      color={labeledStyles.buttons.color}
                      fontFamily={labeledStyles.buttons.fontFamily}
                      onClick={() => dispatch(setConfirmationModalAction(true))}
                    />
                    <Button
                      id='clear'
                      content={<span>Clear</span>}
                      size='small'
                      background={'transparent'}
                      hoverBackground={'transparent'}
                      borderColor={'transparent'}
                      hoverBorderColor={'transparent'}
                      color={'grey'}
                      hoverColor={labeledStyles.buttons.color}
                      fontFamily={labeledStyles.buttons.fontFamily}
                      onClick={() => clearArrayOfPatientsToSendForm(dispatch)}
                    />
                  </div>
                )}
            </div>
            <Table />
          </div>
        {state.isSurveyOpen && <SurveyModal />}
        {state.isRebookModalOpen && <RebookModal toggleModal={setRebookModalOpen} />}
        {state.isConfirmationOpen && <ConfirmationModal />}
        {state.notification && <Notification />}
      </div>
    )
  }
}

export default Dashboard
