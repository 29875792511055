import React, { useRef, useEffect, useContext } from 'react'
import { Context, setConfirmationModalAction, setNotification } from '../../context/Context'
import { sendBulkSurveysApi } from '../../api/api'
import { Button } from 'docspera-components'
import './ConfirmationModal.scss'
import { labeledStyles } from '../../styles'

const ConfirmationModal = () => {
  const { state, dispatch } = useContext(Context)

  console.log('state', state)
  console.log('labeledStyles', labeledStyles)

  const handleExitClick = () => {
    dispatch(setConfirmationModalAction(false))
  }

  const node = useRef(null)
  const handleOutsideClick = (e) => {
    if (node.current) {
      if (node.current.contains(e.target)) {
        return
      }
    }
    dispatch(setConfirmationModalAction(false))
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.current])

  const handleSendBulkSurveysClick = () => {
    sendBulkSurveysApi(state.arrayOfPatientsToSendForm).then((response) => {
      console.log('handleSendBulk response from conf. modal', response)
      if (response.status === 200) {
        dispatch(setNotification('Bulk Send Successful'))
        dispatch(setConfirmationModalAction(false))
      } else {
        dispatch(setNotification('Bulk send failed. Please contact Customer Support.'))
      }
    })
  }

  return (
    <div id='confirmation-modal-component'>
      <div id='background' style={{ backgroundColor: labeledStyles.modal.extBackground }}></div>
      <div
        className='confirmation-modal-div'
        ref={node}
        style={{ borderRadius: labeledStyles.modal.borderRadius }}
      >
        <div className='conf-modal' id='confirmation-modal'>
          <img
            className='conf-exit'
            onClick={() => handleExitClick()}
            src='../../images/exit.svg'
            alt='exit modal'
            style={{ float: 'right' }}
          />

          {state.arrayOfPatientsToSendForm.length !== state.cancelledCases.length ? (
            <h3
              style={{
                fontFamily: labeledStyles.modal.h3.fontFamily,
                fontSize: labeledStyles.modal.h3.fontSize,
                color: labeledStyles.modal.h3.color,
                fontWeight: 600,
                marginTop: 70,
                marginBottom: 70,
                textAlign: 'center'
              }}
            >
              Send Intake Form to ({state.arrayOfPatientsToSendForm.length}) Patients?
            </h3>
          ) : (
            <h3
              style={{
                fontFamily: labeledStyles.modal.h3.fontFamily,
                fontSize: labeledStyles.modal.h3.fontSize,
                color: labeledStyles.modal.h3.color,
                fontWeight: 600,
                marginTop: 70,
                marginBottom: 70,
                textAlign: 'center'
              }}
            >
              Send Intake Form to All Cancelled Patients?
            </h3>
          )}

          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              content={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src='../../images/send_icon.svg' alt='send icon' style={{ width: 16 }} />
                  {state.arrayOfPatientsToSendForm.length > 0 ? (
                    <span style={{ fontFamily: labeledStyles.buttons.fontFamily, marginLeft: 10 }}>
                      Confirm & Send
                    </span>
                  ) : (
                    <span style={{ fontFamily: labeledStyles.buttons.fontFamily, marginLeft: 10 }}>
                      Confirm: Send to All
                    </span>
                  )}
                </div>
              }
              size='medium'
              background={labeledStyles.buttons.background}
              hoverStyles={{
                background: labeledStyles.buttons.hoverBackground,
                borderColor: labeledStyles.buttons.hoverBorderColor,
                color: labeledStyles.buttons.hoverColor
              }}
              borderColor={labeledStyles.buttons.borderColor}
              color={labeledStyles.buttons.color}
              fontFamily={labeledStyles.buttons.fontFamily}
              onClick={() => handleSendBulkSurveysClick()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
